<template>
    <div class="AttributeView q-pa-md">
        <div v-if="!attributesLoading && !categoricalAndNumericLoading">
            <div class="Buttons">
                <QBtn
                    noCaps
                    icon="dashboard_customize"
                    :loading="attributesLoading"
                    :disable="sessionCharts.length >= 4 || attributesLoading"
                    :label="$t('visualization.attributes.add')"
                    color="primary"
                    @click="addChart"
                />
                <QBtn
                    noCaps
                    icon="save"
                    :loading="attributesLoading"
                    :disable="attributesLoading"
                    :label="$t('visualization.attributes.save')"
                    color="primary"
                    @click="saveCharts"
                />
                <span
                    v-if="hasChanges"
                    class="WarningText"
                >
                    {{ $t('visualization.attributes.unsavedChanges') }}
                </span>
            </div>
            <Transition>
                <div
                    v-if="sessionCharts.length === 0"
                    class="flex justify-center items-center q-ma-xl text-h6"
                >
                    {{ $t('visualization.attributes.noCharts') }}
                </div>
            </Transition>
            <transition-group
                name="fade"
                tag="div"
                class="row wrap q-mt-md q-col-gutter-md"
            >
                <div
                    v-for="(chart, index) in sessionCharts"
                    :key="chart.id"
                    class="col-6"
                >
                    <AttributeComponent
                        :attributes="attributes"
                        :categoricalAttributes="categoricalAttributes"
                        :numericAttributes="numericAttributes"
                        :processId="processId"
                        :index="index"
                        :configuration="chart.configuration"
                        @remove="removeChart"
                        @update="updateChart"
                    />
                </div>
            </transition-group>
        </div>
        <q-inner-loading
            v-else
            :showing="attributesLoading || categoricalAndNumericLoading"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import isEqual from 'lodash/isEqual'
import AttributeComponent from './AttributeComponent.vue'
import { Api, apiRequest, notifyError, notifySuccess } from '@/api'

export default {
    name: 'AttributeView',
    components: {
        AttributeComponent,
    },
    props: {
        projectId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        attributes: VueTypes.array,
        categoricalAttributes: VueTypes.array.def(undefined),
        numericAttributes: VueTypes.array.def(undefined),
        attributesLoading: VueTypes.bool.def(true),
        categoricalAndNumericLoading: VueTypes.bool.def(true),
    },
    data () {
        return {
            sessionCharts: [],
            savedCharts: [],
        }
    },
    computed: {
        hasChanges () {
            const sessionConfigs = this.sessionCharts.map(chart => chart.configuration)
            const savedConfigs = this.savedCharts.map(chart => chart.configuration)
            return !isEqual(sessionConfigs, savedConfigs)
        },
    },
    mounted () {
        this.loadCharts()
    },
    methods: {
        addChart () {
            if (this.sessionCharts.length < 4) {
                const newChart = { id: Date.now(), configuration: null }
                this.sessionCharts.push(newChart)
                sessionStorage.setItem('sessionCharts', JSON.stringify(this.sessionCharts))
            }
        },
        removeChart (index) {
            this.sessionCharts.splice(index, 1)
            sessionStorage.setItem('sessionCharts', JSON.stringify(this.sessionCharts))
        },
        updateChart ({ index, configuration }) {
            if (this.sessionCharts[index]) {
                this.sessionCharts[index].configuration = configuration
                sessionStorage.setItem('sessionCharts', JSON.stringify(this.sessionCharts))
            }
        },
        loadCharts () {
            const sessionSavedCharts = sessionStorage.getItem('sessionCharts')
            apiRequest(Api().visualizations.getAttributePlots({ processId: this.processId }))
                .then((charts) => {
                    const formattedCharts = charts.map(chart => ({
                        id: chart.id,
                        configuration: { ...chart },
                    }))
                    if (sessionSavedCharts) {
                        this.sessionCharts = JSON.parse(sessionSavedCharts)
                        this.savedCharts = JSON.parse(JSON.stringify(formattedCharts))
                    } else {
                        this.sessionCharts = [...formattedCharts]
                        this.savedCharts = JSON.parse(JSON.stringify(formattedCharts))
                        sessionStorage.setItem('sessionCharts', JSON.stringify(formattedCharts))
                    }
                })
                .catch(notifyError)
        },
        saveCharts () {
            this.sessionCharts = this.sessionCharts.map(chart => ({
                ...chart,
                configuration: {
                    ...chart.configuration,
                    id: Date.now(),
                },
            }))
            const params = {
                plots: this.sessionCharts.map(chart => chart.configuration),
            }
            apiRequest(Api().visualizations.saveAttributePlots({ processId: this.processId, params }))
                .then(() => {
                    notifySuccess(this.$t('visualization.attributes.savedCharts'))
                    sessionStorage.removeItem('sessionCharts')
                    this.savedCharts = JSON.parse(JSON.stringify(this.sessionCharts))
                })
                .catch(notifyError)
        },
    },
}
</script>

<style scoped lang="scss">
.AttributeView {
    width: 100%;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
.Buttons {
    display: flex;
    flex: row;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.WarningText {
    color: $warning;
    font-weight: bold;
}
</style>
