<template>
    <div class="Sidebar">
        <div class="FiltersContainer">
            <SimpleContextualInfo
                ref="SimpleContextualInfo"
                :totalInfo="infoTotal"
                :filteredInfo="filteredInfo"
                :readOnly="readOnly"
                @on-apply-filter="onApply"
            />
            <div class="ActionContainer">
                <div class="Column Action1">
                    <div class="Action">
                        <QBtn
                            size="12px"
                            icon="filter_alt"
                            dense
                            flat
                            textColor="primary"
                            padding="0px"
                            :disabled="readOnly"
                            @click="handleManageFilters"
                        >
                            <QTooltip>
                                {{ $t('visualization.filters.sidebarTooltips.addFilter') }}
                            </QTooltip>
                        </QBtn>
                    </div>
                    <div class="Action">
                        <QBtn
                            size="12px"
                            icon="restart_alt"
                            padding="0px"
                            flat
                            dense
                            textColor="primary"
                            :disabled="readOnly"
                            @click="handleResetFilters"
                        >
                            <QTooltip>
                                {{ $t('visualization.filters.sidebarTooltips.resetActiveFilters') }}
                            </QTooltip>
                        </QBtn>
                    </div>
                </div>
                <div class="Column Action2">
                    <div class="Action">
                        <QBtn
                            size="12px"
                            icon="list"
                            dense
                            flat
                            textColor="primary"
                            padding="0px"
                            :disabled="readOnly"
                            @click="handleManageFilterSets"
                        >
                            <QTooltip>
                                {{ $t('visualization.filters.sidebarTooltips.manageFilterSets') }}
                            </QTooltip>
                        </QBtn>
                    </div>
                    <div class="Action">
                        <QBtn
                            size="12px"
                            icon="add_circle_outline"
                            dense
                            flat
                            textColor="primary"
                            padding="0px"
                            :disabled="readOnly"
                            @click="handleOpenCreateFilterSetsModal"
                        >
                            <QTooltip>
                                {{ $t('visualization.filters.sidebarTooltips.createFilterSet') }}
                            </QTooltip>
                        </QBtn>
                    </div>
                </div>
                <div class="DropdownAction">
                    <QTooltip v-if="!openOptions">
                        {{ $t('visualization.filters.sidebarTooltips.changeFilterAggregation') }}
                    </QTooltip>
                    <QBtnDropdown
                        ref="dropdown"
                        v-model="openOptions"
                        size="12px"
                        color="primary"
                        dense
                        flat
                        textColor="primary"
                        padding="0px"
                        :disable="readOnly"
                        menuAnchor="bottom end"
                        :label="operator"
                        @click.stop
                    >
                        <QList>
                            <QItem
                                clickable
                                @click="setOperatorAndClose('AND')"
                            >
                                <QItemSection>{{ $t('visualization.filters.filterSets.config.aggregationOperators.AND') }}</QItemSection>
                            </QItem>
                            <QItem
                                clickable
                                @click="setOperatorAndClose('OR')"
                            >
                                <QItemSection>{{ $t('visualization.filters.filterSets.config.aggregationOperators.OR') }}</QItemSection>
                            </QItem>
                        </QList>
                    </QBtnDropdown>
                </div>
            </div>
            <div
                v-if="(totalFilters || []).length"
                class="FilterList t-0-5"
            >
                <div
                    v-for="(filter, index) in totalFilters"
                    :key="`${filter.uuid}-${index}`"
                    class="Filters"
                >
                    <div
                        v-if="!filter.hidden"
                        class="FiltersTextIcon"
                    >
                        <FilterIcon
                            :id="getIconId(filter)"
                            :height="30"
                            :width="30"
                        />
                        <div class="FilterLabel">
                            <QTooltip
                                v-if="filter.id != 'FILTER_SET'"
                                contentClass="text-uppercase"
                                maxWidth="300px"
                            >
                                {{ formatLabelValue(filter).tooltip }}
                            </QTooltip>
                            <WText
                                uppercase
                                :size="12"
                                :color="getColor(filter)"
                            >
                                {{ formatLabelValue(filter).label }}
                            </WText>
                            <WText
                                :size="12"
                                weight="bold"
                                :color="getColor(filter)"
                            >
                                {{ isDateFilter(filter)
                                    ? formatLabelValue(filter).value
                                    : App.numberLocationFormat(formatLabelValue(filter).value, false, true) }}
                            </WText>
                            <WText
                                v-if="formatLabelValue(filter).additionalValue && formatLabelValue(filter).additionalValue !== 'null-null'"
                                :size="12"
                                weight="bold"
                                :color="getColor(filter)"
                            />
                        </div>
                    </div>
                    <q-icon
                        v-if="!filter.hidden"
                        class="DeleteIcon"
                        name="close"
                        size="18px"
                        :clickable="!readOnly"
                        color="primary"
                        style="cursor: pointer;"
                        @click="handleDeleteClick(filter)"
                    />
                </div>
            </div>
            <div v-else>
                <WText
                    class="Empty pt-1"
                    :size="12"
                    align="center"
                >
                    {{ $t('visualization.filters.empty') }}
                </WText>
            </div>
        </div>
    </div>
</template>

<script>
import {
    QBtnDropdown,
    QList,
    QItemSection,
    QTooltip,
} from 'quasar'
import VueTypes from 'vue-types'
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import 'moment-duration-format'
import { mediaQueryMixin, filtersMixin, filtersStorageMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import {
    // eslint-disable-next-line max-len
    ATTRIBUTTE_TYPES, ACTIVITY_FILTERS, DURATION_FILTERS, DATE_FILTERS, ATTRIBUTES_FILTERS, FILTERS_TYPES, ARC_FILTERS, VARIANT_FILTERS, LOOP_FILTERS, NVARIANTS_FILTERS, TRACES_FILTERS,
} from '@/screens/Project/Process/Visualization/sections/FilterModal/filtersEntity'
import { SimpleContextualInfo } from '../components'
import FilterIcon from './FilterModal/components/FilterIcon.vue'

export default {
    name: 'Sidebar',
    components: {
        FilterIcon,
        SimpleContextualInfo,
        QBtnDropdown,
        QList,
        QItemSection,
        QTooltip,
    },
    mixins: [mediaQueryMixin, filtersStorageMixin, filtersMixin],
    inject: ['App'],
    props: {
        tabs: VueTypes.array,
        showAvatar: VueTypes.bool.def(true),
        addMode: VueTypes.bool.def(false),
        processId: VueTypes.oneOfType([String, Number]),
        infoTotal: VueTypes.object,
        filteredInfo: VueTypes.object,
        readOnly: VueTypes.bool.def(false),
    },
    emits: ['hasLoopFilter', 'onManageCreateFilterSets', 'hasVariantFilter', 'onDeleteClick',
        'onManageFilter', 'onManageFilterSets', 'onReplaceFilters', 'onCancel', 'onReset', 'onApply', 'repeatCalls'],
    data () {
        return {
            sets: [],
            isLoading: false,
            variants: undefined,
            operator: 'AND',
            openOptions: false,
        }
    },
    computed: {
        totalFilters () {
            const { visualizationFilters } = this
            return this.formatFilters(visualizationFilters)
        },
        filterByName () {
            return groupBy(this.sets, 'name')
        },
        operators () {
            return [
                {
                    label: this.$t('visualization.filters.filterSets.config.aggregationOperators.AND'),
                    value: 'AND',
                },
                {
                    label: this.$t('visualization.filters.filterSets.config.aggregationOperators.OR'),
                    value: 'OR',
                },
            ]
        },
    },
    watch: {
        generalOperator: {
            handler (newOperator) {
                this.operator = newOperator
            },
            immediate: true,
        },
    },
    mounted () {
        this.$eventBus.on('refreshVariantsNamesAtSideBar', () => {
            this.getVariants()
        })
        this.$eventBus.on('checkForVariantFilter', () => {
            this.checkForVariantFilter()
        })
        this.$eventBus.on('checkForLoopFilter', () => {
            this.checkForLoopFilter()
        })
    },
    methods: {
        checkForLoopFilter () {
            const { visualizationFilters } = this
            const loopFilter = (visualizationFilters || []).find(filter => (LOOP_FILTERS.includes(filter.kind) || LOOP_FILTERS.includes(filter.id)))
            this.$eventBus.emit('hasLoopFilter', loopFilter !== undefined)
        },
        checkForVariantFilter () {
            const { visualizationFilters } = this
            const variantFilter = (visualizationFilters || []).find(filter => (VARIANT_FILTERS.includes(filter.kind) || VARIANT_FILTERS.includes(filter.id)))
            this.$eventBus.emit('hasVariantFilter', variantFilter !== undefined)
            if ((variantFilter !== undefined) && (this.variants === undefined)) {
                this.getVariants()
            }
        },
        async getVariants () {
            this.isLoading = true
            let params = { }
            if (this.infoTotal) {
                const { variants } = this.infoTotal
                params = { limit: variants, start: 0 }
            }
            apiRequest(Api().visualizations.variants({ processId: this.$route.params.processId, params }))
                .then(({ data, total }) => {
                    this.variants = data
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        formatFilters (filters) {
            return (filters || []).map(({
                label, id, key, ...rest
            }) => ({
                id, label, value: key, ...rest,
            }))
        },
        handleDeleteClick (selectedFilter) {
            this.$emit('onDeleteClick', selectedFilter)
        },
        handleManageFilters () {
            this.$emit('onManageFilter')
        },
        handleManageFilterSets () {
            this.$emit('onManageFilterSets')
        },
        handleResetFilters () {
            if (this.totalFilters.length > 0) {
                this.$emit('onReset')
            }
        },
        handleOpenCreateFilterSetsModal () {
            this.$emit('onManageCreateFilterSets')
        },
        isDate (value) {
            return moment(value, moment.ISO_8601, true).isValid()
        },
        formatLabelValue (filter = {}) {
            let formattedLabel
            if (filter.name) {
                formattedLabel = filter.id !== 'FILTER_SET' ? this.$t(`visualization.filters.labels.${filter.label}`) : filter.name
            } else {
                formattedLabel = filter.id !== 'FILTER_SET' ? this.$t(`visualization.filters.labels.${filter.id}`) : filter.name
            }
            if (ACTIVITY_FILTERS.includes(filter.kind) || ACTIVITY_FILTERS.includes(filter.id)) {
                const value = typeof filter.value === 'string' ? filter.value : (filter.value || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (ARC_FILTERS.includes(filter.kind) || ARC_FILTERS.includes(filter.id)) {
                const value = typeof filter.value === 'string' ? filter.value : (filter.value || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (DURATION_FILTERS.includes(filter.kind) || DURATION_FILTERS.includes(filter.id)) {
                let formattedValue = moment.duration(filter.value || filter.values[0]).format('d[D] h[h] m[m] s[s]', { trim: 'all' })
                if (filter.id.includes('ACTIVITIES')) {
                    formattedValue = moment.duration(filter.value || filter.values[filter.values.length - 1]).format('d[D] h[h] m[m] s[s]', { trim: 'all' })
                    if (filter.id.endsWith('LESS_THAN')) {
                        formattedValue = `< ${formattedValue}`
                    } else if (filter.id.endsWith('GREATER_THAN')) {
                        formattedValue = `> ${formattedValue}`
                    }
                }
                const additionalValue = (filter.type || {}).arc || filter.arc || (filter.type || {}).category || filter.activity ||
                    (filter.type && `${(filter.type || {}).activitySource}-${(filter.type || {}).activityTarget}`) ||
                    (filter.values && `${filter.values[0]}-${filter.values[1]}`)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    additionalValue,
                    tooltip: `${formattedLabel}: ${formattedValue}${additionalValue ? ` | ${additionalValue}` : ''}`,
                }
            } if (DATE_FILTERS.includes(filter.kind) || DATE_FILTERS.includes(filter.id)) {
                const formattedValue = filter.value || filter.values[0]
                if (filter.type.category) {
                    return {
                        label: formattedLabel,
                        value: formattedValue,
                        tooltip: `${formattedLabel}: ${formattedValue} | ${filter.type.category}`,
                    }
                }
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (ATTRIBUTES_FILTERS.includes(filter.kind) || ATTRIBUTES_FILTERS.includes(filter.id)) {
                const isBooleanType = ((filter.kind || '').includes(FILTERS_TYPES.ATTRIB_BOOLEAN) || (filter.id || '').includes(FILTERS_TYPES.ATTRIB_BOOLEAN))
                if (isBooleanType) {
                    if (filter.kind) {
                        const bool = ATTRIBUTTE_TYPES.BOOLEAN_ATTRIBUTE.find(a => a.value === filter.id) || {}
                        const formattedValue = typeof filter.attribute === 'string' ? `${filter.attribute} - ${bool.label}` : filter.label
                        return {
                            label: formattedValue,
                            value: '',
                            tooltip: formattedValue,
                        }
                    }
                    return {
                        label: filter.label,
                        value: '',
                        tooltip: filter.label,
                    }
                }
                const attributes = []
                Object.values(ATTRIBUTTE_TYPES).forEach((getTypes) => {
                    const types = getTypes()
                    types.forEach((type) => {
                        attributes.push(type)
                    })
                })
                const attributeFormatterLabel = filter.kind ? `${filter.attribute} - ${(attributes.find(a => a.value === filter.id)).label}` : filter.label
                let formattedValue = filter.value || filter.values[0]
                if (Array.isArray(formattedValue)) {
                    const valores = Object.values(formattedValue)
                    formattedValue = valores.join(', ')
                }
                return {
                    label: attributeFormatterLabel,
                    value: formattedValue,
                    tooltip: `${attributeFormatterLabel}: ${formattedValue}`,
                }
            } if (VARIANT_FILTERS.includes(filter.kind) || VARIANT_FILTERS.includes(filter.id)) {
                let formattedValue = ' '
                if (this.variants !== undefined) {
                    filter.value?.constructor === Array
                        ? formattedValue = filter.value.map((id) => {
                            const variant = this.variants.find(v => v.id === id)
                            variant.name = variant.name.replace('Variant ', '')
                            return variant ? variant.name : null
                        })
                        : formattedValue = filter.values.map((id) => {
                            const variant = this.variants.find(v => v.id === id)
                            variant.name = variant.name.replace('Variant ', '')
                            return variant ? variant.name : null
                        })
                    formattedValue = formattedValue.join(', ')
                }
                this.$eventBus.emit('hasVariantFilter', true)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (NVARIANTS_FILTERS.includes(filter.kind) || NVARIANTS_FILTERS.includes(filter.id)) {
                const formattedValue = filter.type.nvariants
                this.$eventBus.emit('hasVariantFilter', true)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (LOOP_FILTERS.includes(filter.kind) || LOOP_FILTERS.includes(filter.id)) {
                const formattedValue = ""
                this.$eventBus.emit('hasLoopFilter', true)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (TRACES_FILTERS.includes(filter.id)) {
                const value = typeof filter.values === 'string' ? filter.values : (filter.values || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            }
            if (filter.id === 'FILTER_SET') {
                const numberFilters = filter.filters.length > 1 ? 'filters' : 'filter'
                return {
                    label: formattedLabel,
                    value: `${filter.filters.length} ${this.$t(`visualization.filters.filterSets.config.filterSetsTable.${numberFilters}`)}`,
                    // tooltip: filter.filters.map(f => f.id).join('\n'),
                }
            }
            return {
                label: formattedLabel,
                value: filter.value,
                tooltip: `${formattedLabel}: ${filter.value}`,
            }
        },
        getColor ({ pending, toDelete }) {
            if (toDelete) return 'red'
            if (pending) return 'gray03'
            return ''
        },
        getIconId (filterSet) {
            return ATTRIBUTES_FILTERS.includes(filterSet.kind) ? filterSet.kind : filterSet.id
        },
        onApply () {
            this.$emit('onApply')
        },
        updateInfoTotal (infoTotal) {
            this.$refs.SimpleContextualInfo.updateInfoTotal(infoTotal)
        },
        setOperatorAndClose (operator) {
            this.setOperator(operator)
            this.$refs.dropdown.hide()
        },
        setOperator (operator) {
            this.operator = operator
            this.changeGeneralOperator()
        },
        changeGeneralOperator () {
            this.setGeneralOperator(this.operator)
            this.$emit('repeatCalls')
        },
        isDateFilter (filter) {
            if (DATE_FILTERS.includes(filter.id) || FILTERS_TYPES.ATTRIB_DATE === filter.id) {
                return true
            }
            return false
        },
    },
}
</script>
<style scoped lang="scss">
.Filters {
    margin: 10px 5px;
    background: $white;
    display: flex;
    .FilterLabel {
        padding-left: 10px;
        .WText {
            width: auto;
            max-width: 165px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .DeleteIcon {
        padding-left: 5px;
    }
}
.FilterIcon {
    background:  $primary;
    border-radius: 5px;
    padding: 3px;
}
.ActionContainer {
    display: grid;
    grid-template-columns: 1.2fr 1.2fr;
    grid-template-rows: auto auto;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}
.Column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height:100%;
}
.Action {
    margin: 0px 0px;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.DropdownAction {
    grid-column: span 2;
    display: flex;
    justify-content: center;
    margin: 0px 8px;

    ::v-deep .q-btn-dropdown.q-btn-dropdown--simple .q-btn__content .q-btn-dropdown__arrow {
        margin-left: 4px;
    }
}

.FiltersContainer {
    display:flex;
    flex-direction: row;
    width:100%;
    .FilterList {
        display: flex;
        align-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        .Filters {
            align-items: center;
            justify-content: center;
        }
    }
    .Empty {
        padding: 0 15px;
    }
}

.FiltersTextIcon {
    display: flex;
}
</style>
