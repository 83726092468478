import i18n from '@/lang/i18n'

export const FILTERS_TYPES = {
    // DURATION
    TRACE_DURATION_GREATER_THAN: 'TRACE_DURATION_GREATER_THAN',
    TRACE_DURATION_LESS_THAN: 'TRACE_DURATION_LESS_THAN',
    ARC_DURATION_GREATER_THAN: 'ARC_DURATION_GREATER_THAN',
    ARC_DURATION_LESSER_THAN: 'ARC_DURATION_LESSER_THAN',
    ACTIVITY_DURATION_GREATER_THAN: 'ACTIVITY_DURATION_GREATER_THAN',
    ACTIVITY_DURATION_LESS_THAN: 'ACTIVITY_DURATION_LESS_THAN',
    ACTIVITIES_DURATION_GREATER_THAN: 'ACTIVITIES_DURATION_GREATER_THAN',
    ACTIVITIES_DURATION_LESS_THAN: 'ACTIVITIES_DURATION_LESS_THAN',
    ACTIVITIES_DURATION_LOOP_GREATER_THAN: 'ACTIVITIES_DURATION_LOOP_GREATER_THAN',
    ACTIVITIES_DURATION_LOOP_LESS_THAN: 'ACTIVITIES_DURATION_LOOP_LESS_THAN',
    TRACE_DURATION: 'TRACE_DURATION',
    ACTIVITY_DURATION: 'ACTIVITY_DURATION',
    ACTIVITIES_DURATION: 'ACTIVITIES_DURATION',
    ARC_DURATION: 'ARC_DURATION',
    // DATE
    TRACE_STARTS_BEFORE: 'TRACE_STARTS_BEFORE',
    TRACE_ENDS_BEFORE: 'TRACE_ENDS_BEFORE',
    TRACE_STARTS_AFTER: 'TRACE_STARTS_AFTER',
    TRACE_ENDS_AFTER: 'TRACE_ENDS_AFTER',
    // ACTIVITIES
    TRACE_STARTS_WITH_ACTIVITY: 'TRACE_STARTS_WITH_ACTIVITY',
    TRACE_NOT_STARTS_WITH_ACTIVITY: 'TRACE_NOT_STARTS_WITH_ACTIVITY',
    TRACE_ENDS_WITH_ACTIVITY: 'TRACE_ENDS_WITH_ACTIVITY',
    TRACE_NOT_ENDS_WITH_ACTIVITY: 'TRACE_NOT_ENDS_WITH_ACTIVITY',
    TRACE_CONTAINS_ACTIVITIES: 'TRACE_CONTAINS_ACTIVITIES',
    TRACE_NOT_CONTAINS_ACTIVITIES: 'TRACE_NOT_CONTAINS_ACTIVITIES',
    TRACE_NOT_CONTAINS_ARCS: 'TRACE_NOT_CONTAINS_ARCS',
    TRACE_CONTAINS_ARCS: 'TRACE_CONTAINS_ARCS',
    ACTIVITY_STARTS_BEFORE: 'ACTIVITY_STARTS_BEFORE',
    ACTIVITY_STARTS_AFTER: 'ACTIVITY_STARTS_AFTER',
    ACTIVITY_ENDS_BEFORE: 'ACTIVITY_ENDS_BEFORE',
    ACTIVITY_ENDS_AFTER: 'ACTIVITY_ENDS_AFTER',
    ACTIVITIES_HAPPEN_IN_ORDER: 'ACTIVITIES_HAPPEN_IN_ORDER',
    // ATTRIBUTES
    ATTRIB_NUMBERS: 'NUMERIC_ATTRIBUTE',
    ATTRIB_TEXT: 'CATEGORICAL_ATTRIBUTE',
    ATTRIB_BOOLEAN: 'BOOLEAN_ATTRIBUTE',
    ATTRIB_DATE: 'DATETIME_ATTRIBUTE',
    // VARIANTS
    TRACE_IS_IN_VARIANTS: 'TRACE_IS_IN_VARIANTS',
    // LOOPS
    TRACE_IS_IN_LOOP_VARIANTS: 'TRACE_IS_IN_LOOP_VARIANTS',
    // N VARIANTS
    TRACE_IS_IN_N_VARIANTS: 'TRACE_IS_IN_N_VARIANTS',
    // FILTER SETS
    FILTER_SETS: 'FILTER_SET',
    // TRACES
    TRACE_ID_IN: 'TRACE_ID_IN',
}

export const CATEGOTY_ANY = () => i18n.global.t('visualization.filters.attributes.categoryAny')

const ATTRIBUTE_CATEGORICAL_TYPES = () => [
    { label: i18n.global.t('visualization.filters.attributes.attrIs'), value: 'TRACE_ATTRIBUTES_CTG_IN' },
    { label: i18n.global.t('visualization.filters.attributes.attrIsNot'), value: 'TRACE_ATTRIBUTES_CTG_NOT_IN' },
]

const ATTRIBUTE_BOOL_TYPES = () => [
    { label: i18n.global.t('visualization.filters.attributes.attrIsTrueLabel'), value: 'TRACE_ATTRIBUTES_BOOL_TRUE' },
    { label: i18n.global.t('visualization.filters.attributes.attrIsFalseLabel'), value: 'TRACE_ATTRIBUTES_BOOL_FALSE' },
]

const ATTRIBUTE_NUMERICAL_TYPE = () => [
    { label: i18n.global.t('visualization.filters.attributes.attrNumEqualsLabel'), value: 'TRACE_ATTRIBUTES_NUM_EQUALS' },
    { label: i18n.global.t('visualization.filters.attributes.attrNumNotEqualsLabel'), value: 'TRACE_ATTRIBUTES_NUM_NOT_EQUALS' },
    { label: i18n.global.t('visualization.filters.attributes.attrNumGreaterThanLabel'), value: 'TRACE_ATTRIBUTES_NUM_GREATER_THAN' },
    { label: i18n.global.t('visualization.filters.attributes.attrNumLesserThanLabel'), value: 'TRACE_ATTRIBUTES_NUM_LESSER_THAN' },
    { label: i18n.global.t('visualization.filters.attributes.attrNumBetweenLabel'), value: 'TRACE_ATTRIBUTES_NUM_BETWEEN' },
]

const ATTRIBUTE_DATE_TYPE = () => [
    { label: i18n.global.t('visualization.filters.attributes.attrDateAfterLabel'), value: 'TRACE_ATTRIBUTES_DATE_AFTER' },
    { label: i18n.global.t('visualization.filters.attributes.attrDateBeforeLabel'), value: 'TRACE_ATTRIBUTES_DATE_BEFORE' },
    { label: i18n.global.t('visualization.filters.attributes.attrDateBetweenLabel'), value: 'TRACE_ATTRIBUTES_DATE_BETWEEN' },
]
const QUANTITY_TYPE = () => [
    { label: i18n.global.t('visualization.filters.attributes.attrGreaterLabel'), value: 'GREATER_THAN' },
    { label: i18n.global.t('visualization.filters.attributes.attrLesserLabel'), value: 'LESSER_THAN' },
]
const OCCURRENCE_TYPE = () => [
    { label: i18n.global.t('visualization.filters.attributes.attrFirstOccurrenceLabel'), value: 'FIRST' },
    { label: i18n.global.t('visualization.filters.attributes.attrLastOccurrenceLabel'), value: 'LAST' },
]

export const ATTRIBUTTE_TYPES = {
    CATEGORICAL_ATTRIBUTE: ATTRIBUTE_CATEGORICAL_TYPES,
    BOOLEAN_ATTRIBUTE: ATTRIBUTE_BOOL_TYPES,
    NUMERIC_ATTRIBUTE: ATTRIBUTE_NUMERICAL_TYPE,
    DATETIME_ATTRIBUTE: ATTRIBUTE_DATE_TYPE,
    QUANTITY_ATTRIBUTE: QUANTITY_TYPE,
    OCCURRENCE_ATTRIBUTE: OCCURRENCE_TYPE,
}

export const ATTR_FILTERS = [
    {
        label: i18n.global.t('visualization.filters.attributes.filters.numericalField'), type: 'ATTRIBUTE', id: 'attrib_numbers',
    },
    {
        label: i18n.global.t('visualization.filters.attributes.filters.ctgField'), type: 'ATTRIBUTE', id: 'attrib_text',
    },
    {
        label: i18n.global.t('visualization.filters.attributes.filters.booleanField'), type: 'ATTRIBUTE', id: 'attrib_boolean',
    },
    {
        label: i18n.global.t('visualization.filters.attributes.filters.dateTimeField'), type: 'ATTRIBUTE', id: 'attrib_date',
    },
]

export const ACTIVITY_FILTERS = [
    FILTERS_TYPES.TRACE_CONTAINS_ACTIVITIES,
    FILTERS_TYPES.TRACE_ENDS_WITH_ACTIVITY,
    FILTERS_TYPES.TRACE_NOT_ENDS_WITH_ACTIVITY,
    FILTERS_TYPES.TRACE_NOT_CONTAINS_ACTIVITIES,
    FILTERS_TYPES.TRACE_STARTS_WITH_ACTIVITY,
    FILTERS_TYPES.TRACE_NOT_STARTS_WITH_ACTIVITY,
    FILTERS_TYPES.ACTIVITIES_HAPPEN_IN_ORDER,
]
export const ARC_FILTERS = [
    FILTERS_TYPES.TRACE_NOT_CONTAINS_ARCS,
    FILTERS_TYPES.TRACE_CONTAINS_ARCS,
]

export const DURATION_FILTERS = [
    FILTERS_TYPES.TRACE_DURATION_GREATER_THAN,
    FILTERS_TYPES.TRACE_DURATION_LESS_THAN,
    FILTERS_TYPES.ARC_DURATION_GREATER_THAN,
    FILTERS_TYPES.ARC_DURATION_LESSER_THAN,
    FILTERS_TYPES.ACTIVITY_DURATION_GREATER_THAN,
    FILTERS_TYPES.ACTIVITY_DURATION_LESS_THAN,
    FILTERS_TYPES.ACTIVITIES_DURATION_GREATER_THAN,
    FILTERS_TYPES.ACTIVITIES_DURATION_LESS_THAN,
    FILTERS_TYPES.ACTIVITIES_DURATION_LOOP_GREATER_THAN,
    FILTERS_TYPES.ACTIVITIES_DURATION_LOOP_LESS_THAN,
]

export const DATE_FILTERS = [
    FILTERS_TYPES.TRACE_ENDS_AFTER,
    FILTERS_TYPES.TRACE_ENDS_BEFORE,
    FILTERS_TYPES.TRACE_STARTS_AFTER,
    FILTERS_TYPES.TRACE_STARTS_BEFORE,
    FILTERS_TYPES.ACTIVITY_STARTS_BEFORE,
    FILTERS_TYPES.ACTIVITY_STARTS_AFTER,
    FILTERS_TYPES.ACTIVITY_ENDS_BEFORE,
    FILTERS_TYPES.ACTIVITY_ENDS_AFTER,
]

export const ATTRIBUTES_FILTERS = [
    FILTERS_TYPES.ATTRIB_NUMBERS,
    FILTERS_TYPES.ATTRIB_TEXT,
    FILTERS_TYPES.ATTRIB_BOOLEAN,
    FILTERS_TYPES.ATTRIB_DATE,
]

export const VARIANT_FILTERS = [
    FILTERS_TYPES.TRACE_IS_IN_VARIANTS,
]

export const LOOP_FILTERS = [
    FILTERS_TYPES.TRACE_IS_IN_LOOP_VARIANTS,
]

export const NVARIANTS_FILTERS = [
    FILTERS_TYPES.TRACE_IS_IN_N_VARIANTS,
]

export const TRACES_FILTERS = [
    FILTERS_TYPES.TRACE_ID_IN,
]

export const FILTER_SETS = [
    FILTERS_TYPES.FILTER_SETS,
]
