<template>
    <div
        :class="wizardMode? 'Embedded' : 'Screen'"
    >
        <div
            v-if="settings && totalInfo && attributes"
            class="flex row-start-top q-ma-xl"
        >
            <div
                class="SettingsModal"
            >
                <div
                    ref="SettingsContainer"
                    class="SettingsContainer"
                >
                    <div class="row items-center q-gutter-md">
                        <div class="col-auto">
                            <div class="text-h5">
                                {{ $t('visualization.settings.name') }}
                            </div>
                            <div class="text-subtitle1 text-primary">
                                {{ $t('visualization.settings.description') }}
                            </div>
                        </div>
                        <div
                            v-if="!wizardMode && (pendingRelevant || pendingSettings)"
                            class="col-auto"
                        >
                            <q-icon
                                name="warning"
                                color="warning"
                                size="32px"
                            >
                                <q-tooltip>
                                    {{ $t('visualization.settings.pendingChanges') }}
                                </q-tooltip>
                            </q-icon>
                        </div>
                    </div>
                    <div class="row">
                        <q-list class="col-4 q-gutter-sm">
                            <q-item>
                                <q-item-section>
                                    <q-item-label class="partTitleText">
                                        {{ $t('visualization.settings.type.general') }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-ripple
                                clickable
                                :active="panel === 'sla'"
                                class="rounded-borders"
                                activeClass="inset-shadow activeItem"
                                @click="handleChangeTab('sla')"
                            >
                                <q-item-section avatar>
                                    <q-icon
                                        name="schedule"
                                        color="primary"
                                        size="36px"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-h6 text-primary">
                                        {{ $t('visualization.settings.sla.name') }}
                                    </q-item-label>
                                    <q-item-label
                                        v-if="settings.sla && settings.sla.value !== -1"
                                        class="text-black"
                                    >
                                        {{ settings.sla.value }} {{ $t('visualization.settings.sla.units.'+ settings.sla.unit) }}
                                    </q-item-label>
                                    <q-item-label
                                        v-if="(settings.variantsSla && settings.variantsSla.length > 0) || (settings.customSla && settings.customSla.length > 0)"
                                        class="text-black"
                                    >
                                        {{ getAdvacedSlaText() }}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section
                                    v-if="wizardMode && !slaCheck"
                                    side
                                >
                                    <q-icon
                                        class="Icon"
                                        name="warning"
                                        color="warning"
                                        size="40px"
                                    >
                                        <q-tooltip
                                            anchor="top middle"
                                            self="bottom middle"
                                            :offset="[10, 10]"
                                            class="bg-warning"
                                        >
                                            {{ $t('wizard.boosterWizard.mustConfigure') }}
                                        </q-tooltip>
                                    </q-icon>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-ripple
                                clickable
                                :active="panel === 'cost'"
                                class="rounded-borders"
                                activeClass="inset-shadow activeItem"
                                @click="handleChangeTab('cost')"
                            >
                                <q-item-section avatar>
                                    <q-icon
                                        name="payments"
                                        color="primary"
                                        size="36px"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-h6 text-primary">
                                        {{ $t('visualization.settings.costs.name') }}
                                    </q-item-label>
                                    <q-item-label
                                        v-if="settings.cost"
                                        class="text-black"
                                    >
                                        {{ App.numberLocationFormat(settings.cost.value) }} {{ $t('visualization.settings.costs.units.'+settings.cost.unit) }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-ripple
                                clickable
                                :active="panel === 'WorkingDays'"
                                class="rounded-borders"
                                activeClass="inset-shadow activeItem"
                                @click="handleChangeTab('WorkingDays')"
                            >
                                <q-item-section avatar>
                                    <q-icon
                                        name="event"
                                        color="primary"
                                        size="36px"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-h6 text-primary">
                                        {{ $t('visualization.settings.workingDays.name') }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-item-label class="partTitleText">
                                        {{ $t('visualization.settings.type.insights') }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-ripple
                                clickable
                                :active="panel === 'completion'"
                                class="rounded-borders"
                                activeClass="inset-shadow activeItem"
                                @click="handleChangeTab('completion')"
                            >
                                <q-item-section avatar>
                                    <q-icon
                                        name="schema"
                                        color="primary"
                                        size="36px"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-h6 text-primary">
                                        {{ $t('visualization.settings.caseCompletion.name') }}
                                    </q-item-label>
                                    <q-item-label
                                        v-if="!settings.caseCompletion || settings.caseCompletion && settings.caseCompletion.startingActivities.length === 0
                                            && settings.caseCompletion.occurringActivities.length === 0
                                            && settings.caseCompletion.endingActivities.length === 0"
                                        class="text-black"
                                    >
                                        {{ $t('visualization.settings.caseCompletionConditionsNoConf') }}
                                    </q-item-label>
                                    <q-item-label
                                        v-else
                                        class="text-black"
                                    >
                                        {{ $t('visualization.settings.caseCompletionConditionsConf') }}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section
                                    v-if="wizardMode && !caseCompletionCheck"
                                    side
                                >
                                    <q-icon
                                        class="Icon"
                                        name="warning"
                                        color="warning"
                                        size="36px"
                                    >
                                        <q-tooltip
                                            anchor="top middle"
                                            self="bottom middle"
                                            :offset="[10, 10]"
                                            class="bg-warning"
                                        >
                                            {{ $t('wizard.boosterWizard.mustConfigure') }}
                                        </q-tooltip>
                                    </q-icon>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-ripple
                                clickable
                                :active="panel === 'attributes'"
                                class="rounded-borders"
                                activeClass="inset-shadow activeItem"
                                :disable="relevantAttributesDisabled"
                                @click="handleChangeTab('attributes')"
                            >
                                <q-item-section avatar>
                                    <q-icon
                                        name="star"
                                        color="primary"
                                        size="36px"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-h6 text-primary">
                                        {{ $t('visualization.settings.relevantAttributes.name') }}
                                    </q-item-label>
                                    <q-item-label
                                        v-if="relevantAttributes.length < 1"
                                        class="text-black"
                                    >
                                        {{ $t('visualization.settings.relevantAttributes.noRelevant') }}
                                    </q-item-label>
                                    <q-item-label
                                        v-else-if="!relevantAttributesDisabled"
                                        class="text-black"
                                    >
                                        {{ relevantAttributes.join(', ') }}
                                    </q-item-label>
                                    <q-item-label
                                        v-else
                                        class="text-black"
                                    >
                                        {{ $t('visualization.settings.relevantAttributes.disabled') }}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section
                                    v-if="wizardMode && !relevantAttributesCheck"
                                    side
                                >
                                    <q-icon
                                        class="Icon"
                                        name="warning"
                                        color="warning"
                                        size="36px"
                                    >
                                        <q-tooltip
                                            anchor="top middle"
                                            self="bottom middle"
                                            :offset="[10, 10]"
                                            class="bg-warning"
                                        >
                                            {{ $t('wizard.boosterWizard.mustConfigure') }}
                                        </q-tooltip>
                                    </q-icon>
                                </q-item-section>
                            </q-item>
                        </q-list>
                        <div class="col-8">
                            <q-tab-panels
                                v-model="panel"
                                animated
                            >
                                <q-tab-panel name="sla">
                                    <SlaSettings
                                        ref="SlaSettings"
                                        :values="{ sla: settings.sla, customSla: settings.customSla, variantsSla: settings.variantsSla }"
                                        :globalInfo="totalInfo"
                                        @SlaConfig="updateSettings"
                                    />
                                </q-tab-panel>
                                <q-tab-panel name="cost">
                                    <CostCurrencySettings
                                        ref="CostCurrencySettings"
                                        :values="{ cost: settings.cost, activityCosts: settings.activityCosts }"
                                        @CostCurrencyConfig="updateSettings"
                                    />
                                </q-tab-panel>
                                <q-tab-panel
                                    name="WorkingDays"
                                >
                                    <WorkingDays
                                        ref="WorkingDays"
                                    />
                                </q-tab-panel>
                                <q-tab-panel name="completion">
                                    <CaseCompletionConditions
                                        ref="CaseCompletionConditions"
                                        class="caseCompletionSettings"
                                        :values="settings.caseCompletion"
                                        @CaseCompletionConditionsConfig="updateSettings"
                                    />
                                </q-tab-panel>
                                <q-tab-panel name="prediction">
                                    <PredictionAlgorithmSettings
                                        ref="PredictionAlgorithm"
                                        :values="{ predictionAlgorithm: settings.predictionAlgorithm,
                                                   sequenceModelStatus: settings.sequenceModelStatus,
                                                   durationModelStatus: settings.durationModelStatus,
                                        }"
                                        @PredictionAlgorithm="updateSettings"
                                    />
                                </q-tab-panel>
                                <q-tab-panel name="attributes">
                                    <RelevantAttributesSettings
                                        ref="RelevantAttributesSettings"
                                        class="relevantAttributesSettings"
                                        :attributes="optionsAttributes"
                                        :values="relevantAttributes"
                                        @UpdateAttributes="updateAttributes"
                                    />
                                </q-tab-panel>
                            </q-tab-panels>
                            <div class="row justify-end q-pr-md">
                                <q-btn
                                    v-if="!wizardMode"
                                    noCaps
                                    :label="$t('visualization.settings.saveSettings')"
                                    icon="done"
                                    class="bg-primary text-white"
                                    @click="saveAll"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="flex justify-center items-center"
            style="height:400px;"
        >
            <QCircularProgress
                indeterminate
                rounded
                size="50px"
                color="primary"
                class="q-ma-md"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

import {
    Api,
    apiRequest,
    notifyError,
    notifySuccess,
} from '@/api'
import SlaSettings from './components/SlaSettings.vue'
import CostCurrencySettings from './components/CostCurrencySettings.vue'
import CaseCompletionConditions from './components/CaseCompletionConditions.vue'
import PredictionAlgorithmSettings from './components/PredictionAlgorithmSettings.vue'
import RelevantAttributesSettings from './components/RelevantAttributesSettings.vue'
import { filtersStorageMixin } from '@/mixins'
import WorkingDays from './components/WorkingDays.vue'

const TABS = {
    TARGET_DURATION: 'sla',
    CURRENCY_COST: 'cost',
    COMPLETION_CONDITIONS: 'completion',
    PREDICTION: 'prediction',
    RELEVANT_ATTRIBUTES: 'attributes',
}

export default {
    name: 'SettingsModal',
    components: {
        SlaSettings,
        CostCurrencySettings,
        CaseCompletionConditions,
        PredictionAlgorithmSettings,
        RelevantAttributesSettings,
        WorkingDays,
    },
    mixins: [filtersStorageMixin],
    inject: ['App'],
    props: {
        wizardMode: VueTypes.bool.def(false),
        slaCheck: VueTypes.bool.def(true),
        caseCompletionCheck: VueTypes.bool.def(true),
        relevantAttributesCheck: VueTypes.bool.def(true),
    },
    emits: [
        'settingsToWizard',
        'relevantToWizard',
        'saved',
    ],
    data () {
        return {
            processId: this.$route.params.processId || undefined,
            settings: undefined,
            settingsToSave: {},
            attributes: undefined,
            relevantAttributes: undefined,
            categoricalAttributes: undefined,
            optionsAttributes: undefined,
            totalInfo: undefined,
            panel: TABS.TARGET_DURATION,
            predictionAlgorithmDisabled: true,
            relevantAttributesDisabled: true,
            pendingSettings: false,
            pendingRelevant: false,
        }
    },
    mounted () {
        this.getTotalInfo()
        this.setAttributes()
        this.getSettings()
    },
    methods: {
        getTotalInfo () {
            const params = { filters: [] }
            apiRequest(Api().visualizations.modelInfo({ processId: this.processId, params }))
                .then((data) => {
                    this.totalInfo = data
                })
                .catch(notifyError)
                .finally()
        },
        getSettings () {
            apiRequest(Api().datasets.get({ datasetId: this.processId }))
                .then(({ data }) => {
                    [this.settings] = data
                    this.checkPredictionAvailable()
                })
                .catch(notifyError)
                .finally()
        },
        setAttributes () {
            Promise.all([this.getConfigurations(), this.getCategoricalAttributes()])
                .then(() => {
                    this.optionsAttributes = this.attributes.filter((item) => {
                        const isStringAndInCategorical = item.type === 'STRING' &&
                            this.categoricalAttributes.some(cat => cat.name === item.name)
                        return isStringAndInCategorical
                    }).map(i => i.name)
                })
                .catch(notifyError)
        },
        getConfigurations () {
            return apiRequest(Api().datasets.getConfigurations({ datasetId: this.processId }))
                .then(({ data }) => {
                    const [datasetConfig] = data
                    this.attributes = datasetConfig?.columns || []
                    this.relevantAttributes = this.attributes
                        .filter(c => c.relevant === true)
                        .map(c => c.name)
                    this.checkRelevantAttrAvailable()
                })
                .catch(notifyError)
        },
        getCategoricalAttributes () {
            const { processId } = this
            const params = { filters: [], filterSets: [] }
            return apiRequest(Api().visualizations.attributes({ processId, params }))
                .then((res) => {
                    this.categoricalAttributes = res || []
                })
                .catch(notifyError)
        },
        saveSettings (settings) {
            apiRequest(Api().datasets.update({ datasetId: this.$route.params.processId, params: settings }))
                .then(() => {
                    this.getSettings()
                    this.pendingSettings = false
                    notifySuccess(this.$t('visualization.settings.settingsSaved'))
                })
                .catch(notifyError)
                .finally(() => {
                    this.settingsToSave = {}
                })
        },
        saveRelevant (relevant) {
            apiRequest(Api().datasets.updateRelevant({ datasetId: this.processId, context: 'SETTINGS', params: { columnNames: relevant } }))
                .then(() => {
                    this.setAttributes()
                    this.pendingRelevant = false
                    notifySuccess(this.$t('visualization.settings.settingsSaved'))
                })
                .catch(notifyError)
        },
        saveAll () {
            if (this.pendingRelevant) this.saveRelevant(this.relevantAttributes)
            if (this.pendingSettings) this.saveSettings(this.settingsToSave)
        },
        saveInWizard () {
            apiRequest(Api().datasets.update({ datasetId: this.$route.params.processId, params: this.settingsToSave }))
                .then(() => {
                    this.getSettings()
                    this.pendingSettings = false
                    apiRequest(Api().datasets.updateRelevant({ datasetId: this.processId, context: 'SETTINGS', params: { columnNames: this.relevantAttributes } }))
                        .then(() => {
                            this.setAttributes()
                            this.pendingRelevant = false
                            notifySuccess(this.$t('visualization.settings.settingsSaved'))
                            this.$emit('saved')
                        })
                        .catch(notifyError)
                })
                .catch(notifyError)
                .finally()
        },
        updateSettings (settings) {
            if (settings.type === 'activityCosts' || settings.type === 'variantsSla' || settings.type === 'customSla') {
                this.settings[settings.type] = [...settings.data]
                this.settingsToSave[settings.type] = [...settings.data]
            } else {
                this.settings[settings.type] = {
                    ...settings.data,
                }
                this.settingsToSave[settings.type] = {
                    ...settings.data,
                }
            }
            this.pendingSettings = true
            if (this.wizardMode) this.$emit('settingsToWizard', this.settingsToSave)
        },
        updateAttributes (relevant) {
            this.relevantAttributes = [...relevant]
            this.pendingRelevant = true
            if (this.wizardMode) this.$emit('relevantToWizard', this.relevantAttributes)
        },
        handleChangeTab (item) {
            this.panel = item
        },
        checkPredictionAvailable () {
            if (this.settings.sla !== null && this.settings.caseCompletion !== null) {
                this.predictionAlgorithmDisabled = false
            } else {
                this.predictionAlgorithmDisabled = true
            }
        },
        checkRelevantAttrAvailable () {
            const relevantAttributes = this.attributes.filter(c => c.type === 'STRING').map(c => c.name)
            if (relevantAttributes.length > 0) {
                this.relevantAttributesDisabled = false
            } else {
                this.relevantAttributesDisabled = true
            }
        },
        getAdvacedSlaText () {
            let advancedSlaText = ''
            const variansSla = this.settings.variantsSla && this.settings.variantsSla.length > 0
            const customSla = this.settings.customSla && this.settings.customSla.length > 0
            if (variansSla && !customSla) {
                advancedSlaText = this.$t('visualization.settings.sla.variantsSlaPresent')
            } else if (!variansSla && customSla) {
                advancedSlaText = this.$t('visualization.settings.sla.customSlaPresent')
            } else if (variansSla && customSla) {
                advancedSlaText = this.$t('visualization.settings.sla.variantsAndCustomSlaPresent')
            }
            return advancedSlaText
        },
    },
}
</script>
<style lang="scss" scoped>
.Screen {
    height: calc(100vh - $header-height);
    overflow: auto;
}
.Embedded {
    height: 100%;
    overflow: auto;
}
.Settings {
    flex-wrap: nowrap;
    overflow: hidden;
}
.SettingsModal {
    min-height: fit-content;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: white;

}
.TitleText {
    font-size: 25px;
    font-weight: bolder;
}
.Description {
    color: $primary;
    width: fit-content;
    font-size: 12px;
    font-weight: normal;
}

.arrowContainer{
    width:45%;
}
.titleContainer{
    width:65%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 10px;
}
.headerContainer{
    display:flex;
    padding-bottom: 20px;
}
.TitleText {
    font-size: 25px;
    font-weight: bolder;
    margin-bottom: 10px;
}
.Description {
    color: $primary;
    width: fit-content;
    font-size: 16px;
    font-weight: normal;
}
.partTitleText {
    margin-top: 1vh;
    font-size: 15px;
    font-weight: bolder;
}
.SettingsContainer {
    margin: 0 auto;
}
.activeItem {
    background-color: rgb(227, 242, 253);
}

</style>
