<template>
    <div
        class="CustomPlot"
    >
        <div
            v-if="title.key === 'FD'"
        >
            <div class="row justify-between q-ma-xs">
                <div class="row items-center">
                    <QIcon
                        class="IconTitle"
                        name="timelapse"
                        color="primary"
                        size="25px"
                    />
                    <div class="Title">
                        {{ title.label }}
                    </div>
                </div>
                <div class="row justify-end">
                    <QBtn
                        round
                        flat
                        icon="close_fullscreen"
                        size="12px"
                        textColor="primary"
                        @click="visible=!visible"
                    />
                </div>
            </div>
            <transition name="slide1">
                <div v-if="visible && attributes.length > 0 && !Object.keys(chart).length == 0">
                    <FrequencyDurationPanel
                        :currentTitle="title"
                        :processId="processId"
                        :filters="filters"
                        :chart="chart"
                        @title="handleClickOption"
                        @chart-loading="handleChartLoading"
                    />
                </div>
            </transition>
        </div>
        <div
            v-if="title.key === 'AI'"
        >
            <div class="row justify-between q-ma-xs">
                <div class="row items-center">
                    <QIcon
                        class="IconTitle"
                        name="query_stats"
                        color="primary"
                        size="25px"
                    />
                    <div class="Title">
                        {{ title.label }}
                    </div>
                </div>
                <div class="row justify-end">
                    <QBtn
                        round
                        flat
                        icon="close_fullscreen"
                        size="12px"
                        textColor="primary"
                        @click="visible=!visible"
                    />
                </div>
            </div>
            <transition name="slide">
                <div v-if="visible && attributes.length > 0 && !Object.keys(chart).length == 0">
                    <AttributeInformationPanel
                        :currentTitle="title"
                        :attributes="attributes"
                        :categoricalAttributes="categoricalAttributes"
                        :numericAttributes="numericAttributes"
                        :processId="processId"
                        :filters="filters"
                        :chart="chart"
                        @title="handleClickOption"
                        @chart-loading="handleChartLoading"
                    />
                </div>
            </transition>
        </div>
        <q-inner-loading :showing="attributes.length == 0 || Object.keys(chart).length == 0 || variantsLoading || chartLoading" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersMixin } from '@/mixins'
import FrequencyDurationPanel from './FrequencyDurationPanel.vue'
import AttributeInformationPanel from './AttributeInformationPanel.vue'

export default {
    name: 'CustomPlotPanels',
    components: { FrequencyDurationPanel, AttributeInformationPanel },
    mixins: [filtersMixin],
    props: {
        attributes: VueTypes.array,
        categoricalAttributes: VueTypes.array,
        numericAttributes: VueTypes.array,
        processId: VueTypes.string,
        filters: VueTypes.array,
        title: VueTypes.object,
        chart: VueTypes.object,
        variantsLoading: VueTypes.bool,
    },
    emits: ['titleSel'],
    data () {
        return {
            currentTitle: this.title,
            visible: false,
            chartLoading: false,
        }
    },
    computed: {
    },
    methods: {
        handleClickOption (sel) {
            this.currentTitle = sel
            this.$emit('titleSel', this.currentTitle)
        },
        handleClose () {
            this.visible = !this.visible
        },
        handleChartLoading (loading) {
            this.chartLoading = loading
        },
    },
}
</script>
<style lang="scss" scoped>
.CustomPlot {
    position: relative;
    background-color: #FFFFFF;
    padding:5px;
    z-index: 99;
    width: 300px;
    max-width: 300px;
    border: 1px solid $primary;
    border-radius: 5px;
  }
  .Title {
    margin-left: 1.7em;
    color: $primary;
    font-weight: 600;
}
.slide-enter-active, .slide-leave-active {
    transition: max-height ease;
}

.slide-enter, .slide-leave-to /* .slide-leave-active en <2.1.8 */ {
    max-height: 0;
}
</style>
