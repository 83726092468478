<template>
    <div>
        <q-card
            flat
            bordered
            class="q-mb-sm q-pa-md"
        >
            <div class="HeaderRow q-pa-md">
                <div class="HeaderContent">
                    <QIcon
                        name="circle"
                        class="SeverityIcon"
                        size="20px"
                        :color="getSeverityColor(alert.severity)"
                    />
                    <WText :size="18">
                        {{ alert?.name }}
                    </WText>
                </div>
                <QIcon
                    class="CloseEvaluationDetails"
                    name="close"
                    size="20px"
                    @click="closeEvaluationDetails"
                />
            </div>
            <div class="DetailsRow q-px-md">
                <WText class="DetailsMargin">
                    <strong>{{ $t('booster.alerts.details.affectedCases') }}:</strong> {{ alert.affectedCases }}
                </WText>
                <WText><strong>{{ $t('booster.alerts.details.lastDetected') }}:</strong> {{ formatDate(alert.timestamp) }} </WText>
            </div>
            <div class="TriggerRow q-pa-md">
                <div>
                    <WText> {{ $t('booster.alerts.details.triggerConditions') }} </WText>
                    <QChip
                        v-for="(filter, index) in alertFilters"
                        :key="index"
                        color="primary"
                    >
                        <div class="ChipContent">
                            <WText
                                uppercase
                                color="white"
                                :size="12"
                            >
                                {{ formatFilterValue(filter).label }}
                            </WText>
                            <WText
                                weight="bold"
                                color="white"
                                :size="12"
                            >
                                {{ App.numberLocationFormat(formatFilterValue(filter).value, false, true) }}
                            </WText>
                            <WText
                                v-if="formatFilterValue(filter).additionalValue && formatFilterValue(filter).additionalValue !== 'null-null'"
                                weight="bold"
                                color="white"
                                :size="12"
                            />
                        </div>
                    </QChip>
                </div>
            </div>
            <div class="TablesRow row q-pa-md">
                <div class="CasesTable">
                    <WText class="TableTitle">
                        {{ $t('booster.alerts.details.affectedCases') }}
                    </WText>
                    <q-table
                        v-model:pagination="casesPagination"
                        :rows="cases"
                        :columns="casesColumns"
                        :rowsPerPageOptions="casesTableRowsPerPageOptions"
                        :loading="casesLoading"
                        rowKey="caseId"
                        class="Clickable col-3 my-sticky-header-table"
                        flat
                        dense
                        bordered
                        wrapCells
                        @request="fetchPaginatedCases"
                    >
                        <template #body-cell-caseId="props">
                            <q-td
                                autoWidth
                                :class="{'selected-row': (props.row === selectedCaseId)}"
                                @click="selectCaseId(props.row)"
                            >
                                {{ props.row }}
                            </q-td>
                        </template>
                    </q-table>
                </div>
                <div class="EventsTable">
                    <WText class="TableTitle">
                        {{ $t('booster.alerts.details.events') }}
                    </WText>
                    <q-table
                        v-if="selectedCaseId"
                        v-model:pagination="eventsPagination"
                        :rows="events"
                        :columns="eventsColumns"
                        :rowsPerPageOptions="eventsTableRowsPerPageOptions"
                        :loading="eventsLoading"
                        rowKey="traceKey"
                        class="col-3 my-sticky-header-table"
                        dense
                        flat
                        bordered
                        @request="fetchPaginatedEvents"
                    >
                        <template #body-cell-activityName="props">
                            <q-td autoWidth>
                                {{ props.row.activityName }}
                            </q-td>
                        </template>
                        <template #body-cell-startTime="props">
                            <q-td autoWidth>
                                {{ props.row.startTime }}
                            </q-td>
                        </template>
                        <template #body-cell-endTime="props">
                            <q-td autoWidth>
                                {{ props.row.endTime }}
                            </q-td>
                        </template>
                    </q-table>
                    <div v-else>
                        <span>
                            {{ $t('booster.alerts.details.selectCaseIdMessage') }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="FooterRow q-pa-md">
                <QBtn
                    noCaps
                    color="primary"
                    :disabled="!selectedCaseId"
                    :label="$t('booster.alerts.details.viewOnTraceViewer')"
                    @click="openTraceViewer"
                />
            </div>
        </q-card>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import {
    ATTRIBUTTE_TYPES, ACTIVITY_FILTERS, DURATION_FILTERS, DATE_FILTERS, ATTRIBUTES_FILTERS, FILTERS_TYPES, ARC_FILTERS, VARIANT_FILTERS, LOOP_FILTERS, NVARIANTS_FILTERS,
} from '@/screens/Project/Process/Visualization/sections/FilterModal/filtersEntity'

const ROWS_PER_PAGE_OPTIONS = [
    10, 15, 20, 25,
]

export default {
    name: 'AlertDetails',
    components: {
    },
    mixins: [],
    inject: ['App'],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        alert: VueTypes.oneOfType([VueTypes.object, VueTypes.array]),
    },
    emits: ['closeEvaluationDetails'],
    data () {
        return {
            selectedCaseId: null,
            alertFilters: [],
            cases: [],
            casesColumns: [
                { name: 'caseId', label: this.$t('booster.alerts.details.caseId'), align: 'left', field: row => row },
            ],
            casesLoading: false,
            casesTablePage: 1,
            casesRowsNumber: 0,
            casesTableRowsPerPage: 10,
            casesTableRowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
            events: [],
            eventsColumns: [],
            eventsLoading: false,
            eventsTablePage: 1,
            eventsRowsNumber: 0,
            eventsTableRowsPerPage: 10,
            eventsTableRowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
        }
    },
    computed: {
        eventsPagination: {
            get () {
                return {
                    page: this.eventsTablePage,
                    rowsPerPage: this.eventsTableRowsPerPage,
                    rowsNumber: this.eventsRowsNumber,
                }
            },
            set (value) {
                this.eventsTablePage = value.page
                this.eventsTableRowsPerPage = value.rowsPerPage
            },
        },
        casesPagination: {
            get () {
                return {
                    page: this.casesTablePage,
                    rowsPerPage: this.casesTableRowsPerPage,
                    rowsNumber: this.casesRowsNumber,
                }
            },
            set (value) {
                this.casesTablePage = value.page
                this.casesTableRowsPerPage = value.rowsPerPage
            },
        },
    },
    created () {
        this.fetchCases()
    },
    methods: {
        fetchCases (params) {
            const { processId } = this
            const formattedParams = params
                ? this.formatCasesParams(params)
                : this.formatCasesParams({
                    page: this.casesTablePage,
                    rowsPerPage: this.casesTableRowsPerPage,
                    alertUuid: this.alert.uuid,
                    alertEvalDate: this.alert.timestamp,
                })
            this.casesLoading = true
            apiRequest(Api().alerts.evaluationDetails({ processId, params: formattedParams }))
                .then((data) => {
                    this.cases = data.cases
                    this.casesRowsNumber = data.affectedCases
                    this.alertFilters = data.filters
                })
                .catch(notifyError)
                .finally(() => (this.casesLoading = false))
        },
        fetchPaginatedCases (props) {
            const rowsPerPage = props.pagination.rowsPerPage === 0 ? 9999 : props.pagination.rowsPerPage
            this.casesPagination = props.pagination
            this.fetchCases({ ...props.pagination, rowsPerPage })
        },
        formatCasesParams (params) {
            const { page, rowsPerPage } = params
            return {
                ...(rowsPerPage ? { start: page - 1 } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                alertUuid: this.alert.uuid || '',
                alertEvalDate: this.alert.timestamp || '',
            }
        },
        selectCaseId (caseId) {
            this.selectedCaseId = caseId
            this.fetchEvents()
        },
        fetchEvents (params) {
            const { processId } = this
            const formattedParams = params
                ? this.formatEventsParams(params)
                : this.formatEventsParams({
                    page: this.eventsTablePage,
                    rowsPerPage: this.eventsTableRowsPerPage,
                    search: this.selectedCaseId,
                })
            this.eventsLoading = true
            apiRequest(Api().visualizations.traces({ processId, params: formattedParams }))
                .then(({ data, total }) => {
                    if (!data.length) {
                        this.events = data
                        this.eventsRowsNumber = total
                        return
                    }
                    this.eventsColumns = this.formatEventsColumns(data)
                    this.events = this.formatEventsRows(data)
                    this.events = this.events.map(r => ({
                        ...r,
                        start: r.start.replace('Z', ''),
                        end: r.end.replace('Z', ''),
                        ...Object.fromEntries(Object.entries(r).filter(([key]) => key !== 'start' && key !== 'end')
                            .map(([key, value]) => [key, this.App.numberLocationFormat(value, true)])),
                    }))
                    this.eventsRowsNumber = total
                })
                .catch(notifyError)
                .finally(() => (this.eventsLoading = false))
        },
        fetchPaginatedEvents (props) {
            const rowsPerPage = props.pagination.rowsPerPage === 0 ? 9999 : props.pagination.rowsPerPage
            this.eventsPagination = props.pagination
            this.fetchEvents({ ...props.pagination, rowsPerPage, search: this.selectedCaseId })
        },
        formatEventsParams (params) {
            const { page, rowsPerPage, search } = params
            return {
                ...(rowsPerPage ? { start: page - 1 } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                search: search || '',
            }
        },
        formatEventsRows (data) {
            return data.map(({ attributes, ...restData }) => ({ ...restData, ...attributes }))
        },
        formatEventsColumns (data) {
            const [{ attributes, ...rest }] = data
            return [
                ...Object.keys(rest).filter(f => f !== 'traceKey').map(f => this.formatEventsColumnsLabels(f, 'primary')),
                ...Object.keys(attributes).map(attrKey => this.formatEventsColumnsLabels(attrKey, 'attribute')).sort((a, b) => a.name.localeCompare(b.name)),
            ]
        },
        formatEventsColumnsLabels (field, type) {
            return {
                name: field,
                label: field,
                field,
                align: 'center',
                format: val => (val ?? '-'),
                headerClasses: `${type === 'primary' ? 'text-white bg-primary' : 'bg-grey-4 text-black'}`,
                classes: `${type === 'primary' ? 'bg-light-blue-1 ellipsis' : 'bg-grey-1 ellipsis text-black'}`,
                style: 'max-width: 500px',
                headerStyle: 'max-width: 300px',
            }
        },
        closeEvaluationDetails () {
            this.$emit('closeEvaluationDetails')
        },
        openTraceViewer () {
            const params = { ...this.$route.params, tab: 'TRACES' }
            const caseId = this.selectedCaseId
            sessionStorage.setItem('selectedCaseId', caseId)
            this.$router.replace({ name: 'ProcessVisualization', params })
        },
        getSeverityColor (severity) {
            const colors = {
                LOW: 'amber-11',
                MEDIUM: 'orange-11',
                HIGH: 'red-11',
            }
            return colors[severity]
        },
        formatDate (timestamp) {
            const date = new Date(timestamp)
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
        },
        formatFilterValue (filter = {}) {
            let formattedLabel
            if (filter.name) {
                formattedLabel = filter.id !== 'FILTER_SET' ? this.$t(`visualization.filters.labels.${filter.label}`) : filter.name
            } else {
                formattedLabel = filter.id !== 'FILTER_SET' ? this.$t(`visualization.filters.labels.${filter.id}`) : filter.name
            }
            if (ACTIVITY_FILTERS.includes(filter.kind) || ACTIVITY_FILTERS.includes(filter.id)) {
                const value = typeof filter.value === 'string' ? filter.value : (filter.value || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                }
            } if (ARC_FILTERS.includes(filter.kind) || ARC_FILTERS.includes(filter.id)) {
                const value = typeof filter.value === 'string' ? filter.value : (filter.value || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                }
            } if (DURATION_FILTERS.includes(filter.kind) || DURATION_FILTERS.includes(filter.id)) {
                let formattedValue = filter.kind
                    ? moment.duration(filter.value || filter.values[0]).format('d[D] h[h]:m[m]:s[s]')
                    : filter.value
                if (filter.id.includes('ACTIVITIES')) {
                    formattedValue = filter.kind
                        ? moment.duration(filter.values[filter.values.length - 1]).format('d[D] h[h]:m[m]:s[s]')
                        : filter.value
                    if (filter.id.endsWith('LESS_THAN')) {
                        formattedValue = `< ${formattedValue}`
                    } else if (filter.id.endsWith('GREATER_THAN')) {
                        formattedValue = `> ${formattedValue}`
                    }
                }
                const additionalValue = (filter.type || {}).arc || filter.arc || (filter.type || {}).category || filter.activity ||
                    (filter.type && `${(filter.type || {}).activitySource}-${(filter.type || {}).activityTarget}`) ||
                    (filter.values && `${filter.values[0]}-${filter.values[1]}`)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    additionalValue,
                }
            } if (DATE_FILTERS.includes(filter.kind) || DATE_FILTERS.includes(filter.id)) {
                const formattedValue = filter.value || filter.values[0]
                return {
                    label: formattedLabel,
                    value: formattedValue,
                }
            } if (ATTRIBUTES_FILTERS.includes(filter.kind) || ATTRIBUTES_FILTERS.includes(filter.id)) {
                const isBooleanType = ((filter.kind || '').includes(FILTERS_TYPES.ATTRIB_BOOLEAN) || (filter.id || '').includes(FILTERS_TYPES.ATTRIB_BOOLEAN))
                if (isBooleanType) {
                    if (filter.kind) {
                        const bool = ATTRIBUTTE_TYPES.BOOLEAN_ATTRIBUTE.find(a => a.value === filter.id) || {}
                        const formattedValue = typeof filter.attribute === 'string' ? `${filter.attribute} - ${bool.label}` : filter.label
                        return {
                            label: formattedValue,
                            value: '',
                        }
                    }
                    return {
                        label: filter.label,
                        value: '',
                    }
                }
                const attributes = []
                Object.values(ATTRIBUTTE_TYPES).forEach((getTypes) => {
                    const types = getTypes()
                    types.forEach((type) => {
                        attributes.push(type)
                    })
                })
                const attributeFormatterLabel = filter.kind ? `${filter.attribute} - ${(attributes.find(a => a.value === filter.id)).label}` : filter.label
                let formattedValue = filter.value || filter.values[0]
                if (Array.isArray(formattedValue)) {
                    const valores = Object.values(formattedValue)
                    formattedValue = valores.join(', ')
                }
                return {
                    label: attributeFormatterLabel,
                    value: formattedValue,
                }
            } if (VARIANT_FILTERS.includes(filter.kind) || VARIANT_FILTERS.includes(filter.id)) {
                let formattedValue = ' '
                if (this.variants !== undefined) {
                    filter.value?.constructor === Array
                        ? formattedValue = filter.value.map((id) => {
                            const variant = this.variants.find(v => v.id === id)
                            variant.name = variant.name.replace('Variant ', '')
                            return variant ? variant.name : null
                        })
                        : formattedValue = filter.values.map((id) => {
                            const variant = this.variants.find(v => v.id === id)
                            variant.name = variant.name.replace('Variant ', '')
                            return variant ? variant.name : null
                        })
                    formattedValue = formattedValue.join(', ')
                }
                return {
                    label: formattedLabel,
                    value: formattedValue,
                }
            } if (NVARIANTS_FILTERS.includes(filter.kind) || NVARIANTS_FILTERS.includes(filter.id)) {
                const formattedValue = filter.type.nvariants
                return {
                    label: formattedLabel,
                    value: formattedValue,
                }
            } if (LOOP_FILTERS.includes(filter.kind) || LOOP_FILTERS.includes(filter.id)) {
                const formattedValue = ""
                return {
                    label: formattedLabel,
                    value: formattedValue,
                }
            }
            if (filter.id === 'FILTER_SET') {
                const numberFilters = filter.filters.length > 1 ? 'filters' : 'filter'
                return {
                    label: formattedLabel,
                    value: `${filter.filters.length} ${this.$t(`visualization.filters.filterSets.config.filterSetsTable.${numberFilters}`)}`,
                }
            }
            return {
                label: formattedLabel,
                value: filter.value,
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Clickable {
    tbody {
        td {
            cursor:pointer;
        }
    }
}
.CloseEvaluationDetails {
    cursor:pointer;
}
.HeaderRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.HeaderContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.SeverityIcon {
    margin-right: 5px;
}
.DetailsRow {
    display: flex;
    flex-direction: row;
}
.DetailsMargin {
    margin-right: 5%;
}
.TriggerRow {
    display: flex;
    flex-direction: row;
}
.ChipContent {
    display: flex;
    gap: 8px;
    align-items: center;
}
.TablesRow {
    display: flex;
    flex-direction: row;
    gap: 5%;
    overflow: auto;
}
.CasesTable {
    max-width: 20%;
}
.EventsTable {
    max-width: 75%;
}
.TableTitle {
    margin-bottom: 5px;
}
.FooterRow {
    display: flex;
    justify-content: flex-end;
}
.my-sticky-header-table {
    height: fit-content;
    overflow: auto;

    &:deep(thead) {
        position: sticky;
        top: 0;
        background: $primary;
        z-index: 99;
        color: white;
    }

    thead tr th {
        position: sticky;
        z-index: 1;
    }
    thead tr:first-child th {
        top: 0;
    }
    tbody {
        td {
                background: $gray-06;
                border-bottom: 1px solid white;
        }
        tr:hover {
            background-color: $gray-05;
          }

        .selected-row {
        background-color: $gray-04  !important;
        // color:$gray-04 !important; /* Azul para el texto de la fila seleccionada */
        }
    }

    &.q-table--loading thead tr:last-child th {
        top: 48px;
    }
}
</style>
