<template>
    <div class="DataNavigator">
        <div class="ButtonsContainer">
            <div class="ButtonGroupContainer">
                <QBtnToggle
                    v-model="tab"
                    toggleColor="primary"
                    noCaps
                    unelevated
                    :options="tabs"
                    class="q-mt-sm border-toggle"
                    @update:modelValue="handleChangeDashboard"
                />
            </div>
            <QBtn
                outline
                noCaps
                color="primary"
                class="PowerBIButton"
                @click="openModal"
            >
                <QIcon
                    name="stacked_bar_chart"
                    size="2em"
                />
                {{ $t('visualization.powerBIDialog.powerBI') }}
            </QBtn>
            <div
                v-if="modalVisible"
                ref="modal"
                class="PowerBIContainer"
            >
                <PowerBIConnector
                    class="PowerBIContent"
                    :processId="processId"
                    @close-modal="closeModal"
                />
            </div>
        </div>
        <div
            v-if="tab === 'VARIANTS'"
            class="ChartsContainer pb-1"
        >
            <!-- variantSorted -->
            <div
                class="flex flex-nowrap"
                :style="{ 'flex-direction': isDesktop ? 'row' : 'column' }"
            >
                <v-chart
                    class="chart"
                    :option="variantSortedByFrequency"
                />
                <v-chart
                    class="chart"
                    :option="variantSortedByDurationAVG"
                />
            </div>
            <div
                class="flex flex-nowrap"
                :style="{ 'flex-direction': isDesktop ? 'row' : 'column' }"
            >
                <v-chart
                    class="chart"
                    :option="variantSortedByDurationMax"
                />
                <v-chart
                    class="chart"
                    :option="variantSortedByDurationMin"
                />
            </div>
            <div
                class="flex flex-nowrap"
                :style="{ 'flex-direction': isDesktop ? 'row' : 'column' }"
            >
                <v-chart
                    class="chart"
                    :option="variantSortedByDurationSTD"
                />
                <!-- variantsOver -->
                <v-chart
                    class="chart"
                    :option="variantsOver"
                />
            </div>
        </div>
        <div
            v-if="tab === 'ACTIVITIES'"
            class="ChartsContainer pb-1"
        >
            <div
                class="flex flex-nowrap"
                :style="{ 'flex-direction': isDesktop ? 'row' : 'column' }"
            >
                <v-chart
                    class="chart"
                    :option="activitiesSortedByFrequency"
                />
                <v-chart
                    class="chart"
                    :option="activitiesSortedByDurationAVG"
                />
            </div>
            <div
                class="flex flex-nowrap"
                :style="{ 'flex-direction': isDesktop ? 'row' : 'column' }"
            >
                <v-chart
                    class="chart"
                    :option="activitiesSortedByDurationMax"
                />
                <v-chart
                    class="chart"
                    :option="activitiesSortedByDurationMin"
                />
            </div>
            <v-chart
                class="chart"
                :option="activitiesSortedByDurationSTD"
            />
        </div>
        <div
            v-if="tab === 'TRANSITIONS'"
            class="ChartsContainer Transitions pb-1"
        >
            <v-chart
                class="chart"
                :option="transitionsSortedByFrequency"
            />
            <v-chart
                class="chart"
                :option="transitionsSortedByDurationAVG"
            />
            <v-chart
                class="chart"
                :option="transitionsSortedByDurationMax"
            />
            <v-chart
                class="chart"
                :option="transitionsSortedByDurationMin"
            />
            <v-chart
                class="chart"
                :option="transitionsSortedByDurationSTD"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import uniq from 'lodash/uniq'
import { sortBy } from 'lodash'
import moment from 'moment'
import 'moment-duration-format'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import { filtersStorageMixin, mediaQueryMixin } from '@/mixins'

import Colors from '@/styles/colors'
import { formatNumber } from '@/utils/formatNumber'
import PowerBIConnector from './PowerBIConnector.vue'

const scatterChartOptions = {
    title: {
        text: '',
        left: 'center',
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
        },
    },
    grid: {
        containLabel: true,
    },
    xAxis: {
        splitLine: {
            lineStyle: {
                type: 'dashed',
            },
        },
        nameLocation: 'middle',
        nameTextStyle: {
            align: 'left',
            verticalAlign: 'top',
            padding: [15, 0],
        },
        axisLabel: {
            interval: 2,
            hideOverlap: true,
            formatter: (value) => {
                const maxLength = 15
                return value.length > maxLength ? `${value.slice(0, maxLength) }...` : value
            },
        },
    },
    yAxis: {
        splitLine: {
            lineStyle: {
                type: 'dashed',
            },
        },
        axisLabel: {
            formatter: (value) => {
                const maxLength = 15
                return value.length > maxLength ? `${value.slice(0, maxLength) }...` : value
            },
        },
    },
    series: {
        symbolSize: 20,
        data: [],
        type: 'scatter',
        itemStyle: {
            color: Colors.secondary,
        },
    },
}

const barChartOptions = {
    title: {
        text: '',
        left: 'center',
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
    },
    xAxis: {
        type: 'value',
        splitLine: {
            lineStyle: {
                type: 'dashed',
            },
        },
        nameLocation: 'middle',
        nameTextStyle: {
            align: 'left',
            verticalAlign: 'top',
            padding: [15, 0],
        },
        axisLabel: {
            formatter: (value) => {
                const maxLength = 15
                return value.length > maxLength ? `${value.slice(0, maxLength) }...` : value
            },
        },
    },
    yAxis: {
        type: 'category',
        splitLine: {
            lineStyle: {
                type: 'dashed',
            },
        },
        data: [],
        axisLabel: {
            formatter: (value) => {
                const maxLength = 15
                return value.length > maxLength ? `${value.slice(0, maxLength) }...` : value
            },
        },
    },
    series: [
        {
            type: 'bar',
            data: [],
            itemStyle: {
                color: Colors.secondary,
            },
        },
    ],
}

const heatmapChartOptions = {
    tooltip: {
        position: 'top',
    },
    autoresize: true,
    grid: {
        containLabel: true,
        right: 0,
        left: 0,
    },
    xAxis: {
        type: 'category',
        data: [],
        splitArea: {
            show: true,
        },
        tooltip: {
            show: true,
        },
        nameTruncate: {
            maxWidth: '10px',
        },
        axisLabel: {
            fontSize: '10px',
            rotate: 45,
            interval: 0,
            formatter: (value) => {
                const maxLength = 15
                return value.length > maxLength ? `${value.slice(0, maxLength) }...` : value
            },
        },
    },
    yAxis: {
        type: 'category',
        data: [],
        splitArea: {
            show: true,
        },
        axisLabel: {
            fontSize: '10px',
            formatter: (value) => {
                const maxLength = 15
                return value.length > maxLength ? `${value.slice(0, maxLength) }...` : value
            },
        },
    },
    visualMap: {
        min: 0,
        max: 10,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '0',
    },
    series: [{
        name: 'Transitions',
        type: 'heatmap',
        data: [],
        label: {
            show: true,
        },
        emphasis: {
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
        },
    }],
}
const ASCENDING = 'ASCENDING'
const DESCENDING = 'DESCENDING'

export default {
    name: 'DataNavigator',
    components: {
        PowerBIConnector,
    },
    mixins: [filtersStorageMixin, mediaQueryMixin],
    props: {
        processId: VueTypes.string,
    },
    data () {
        return {
            tab: 'VARIANTS',
            isLoading: false,
            modalVisible: false,
            flag: false,

            // variantsSorted
            variantSortedByFrequency: barChartOptions,
            variantSortedByDurationAVG: barChartOptions,
            variantSortedByDurationMax: barChartOptions,
            variantSortedByDurationMin: barChartOptions,
            variantSortedByDurationSTD: barChartOptions,

            // activitiesSorted
            activitiesSortedByFrequency: barChartOptions,
            activitiesSortedByDurationAVG: barChartOptions,
            activitiesSortedByDurationMax: barChartOptions,
            activitiesSortedByDurationMin: barChartOptions,
            activitiesSortedByDurationSTD: barChartOptions,

            // variantsOver
            variantsOver: scatterChartOptions,

            // transitionsSorted
            transitionsSortedByFrequency: heatmapChartOptions,
            transitionsSortedByDurationAVG: heatmapChartOptions,
            transitionsSortedByDurationMax: heatmapChartOptions,
            transitionsSortedByDurationMin: heatmapChartOptions,
            transitionsSortedByDurationSTD: heatmapChartOptions,
        }
    },
    computed: {
        tabs () {
            return [
                { label: this.$t('visualization.dashboard.variants'), value: 'VARIANTS' },
                { label: this.$t('visualization.dashboard.activities'), value: 'ACTIVITIES' },
                { label: this.$t('visualization.dashboard.transitions'), value: 'TRANSITIONS' },
            ]
        },
    },
    mounted () {
        this.getVariants()
        document.addEventListener('click', this.closeModalOutside)
    },
    methods: {
        openModal () {
            this.modalVisible = !this.modalVisible
            if (this.modalVisible) { this.flag = true }
        },
        closeModalOutside (event) {
            if (this.modalVisible && !this.flag) {
                const container = this.$refs.modal
                if (container && !container.contains(event.target)) {
                    this.modalVisible = false
                }
            }
            this.flag = false
        },
        closeModal () {
            this.modalVisible = false
            this.flag = false
        },
        getVariants () {
            // variantsSorted
            this.getVariantsSorted('FREQUENCY', 'variantSortedByFrequency')
            this.getVariantsSorted('DURATION_AVG', 'variantSortedByDurationAVG', true)
            this.getVariantsSorted('DURATION_MAX', 'variantSortedByDurationMax', true)
            this.getVariantsSorted('DURATION_MIN', 'variantSortedByDurationMin', true, ASCENDING)
            this.getVariantsSorted('DURATION_STD', 'variantSortedByDurationSTD', true)
            // variantsOver
            this.getVariantsOver()
        },
        getActivities () {
            // activitiesSorted
            this.getActivitiesSorted('FREQUENCY', 'activitiesSortedByFrequency')
            this.getActivitiesSorted('DURATION_AVG', 'activitiesSortedByDurationAVG', true)
            this.getActivitiesSorted('DURATION_MAX', 'activitiesSortedByDurationMax', true)
            this.getActivitiesSorted('DURATION_MIN', 'activitiesSortedByDurationMin', true, ASCENDING)
            this.getActivitiesSorted('DURATION_STD', 'activitiesSortedByDurationSTD', true)
        },
        getTransitions () {
            // transitionsSorted
            this.getTransitionsSorted('FREQUENCY', 'transitionsSortedByFrequency')
            this.getTransitionsSorted('DURATION_AVG', 'transitionsSortedByDurationAVG', true)
            this.getTransitionsSorted('DURATION_MAX', 'transitionsSortedByDurationMax', true)
            this.getTransitionsSorted('DURATION_MIN', 'transitionsSortedByDurationMin', true)
            this.getTransitionsSorted('DURATION_STD', 'transitionsSortedByDurationSTD', true)
        },
        formatDuration (duration) {
            return moment.duration(duration, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })
        },
        formatFrequency (value) {
            const options = {
                decimals: 0, decimalSeparator: '.', compress: true, trimRight: true,
            }
            return formatNumber(value, options)
        },
        handleChangeDashboard (type) {
            if (this.tab === 'TRANSITIONS') this.getTransitions()
            else if (this.tab === 'VARIANTS') this.getVariants()
            else if (this.tab === 'ACTIVITIES') this.getActivities()
        },
        getActivitiesSorted (param, variable, formatDuration, sortDirection = DESCENDING) {
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            const params = {
                datasetUid: this.processId,
                limit: 10,
                sortBy: param,
                sortDirection,
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
            }
            apiRequest(Api().dataNavigator.activitiesSorted(params))
                .then(({ rows: data }) => {
                    const dataByActivity = data.map(({ activity }) => activity)
                    const dataByValue = data.map(({ value }) => value)

                    this[variable] = {
                        ...this[variable],
                        title: {
                            ...this[variable].title,
                            text: this.$t(`visualization.dashboard.sortBy.${param}`),
                        },
                        yAxis: {
                            name: this.$t(`visualization.dashboard.axisLabels.activityId`),
                            ...this[variable].yAxis,
                            data: sortDirection === DESCENDING ? dataByActivity.reverse() : dataByActivity,
                        },
                        xAxis: {
                            name: this.$t(`visualization.dashboard.axisLabels.${formatDuration ? 'duration' : 'frequency'}`),
                            axisLabel: {
                                formatter: value => (formatDuration ? this.formatDuration(value) : value),
                            },
                        },
                        series: this[variable].series.map(serie => ({
                            ...serie,
                            data: sortDirection === DESCENDING ? dataByValue.reverse() : dataByValue,
                        })),
                        tooltip: {
                            ...this[variable].tooltip,
                            formatter: ([{ name, value }]) => `${name}: ${formatDuration ? this.formatDuration(value) : value}`,
                        },
                    }
                })
                .catch(notifyError)
        },
        getVariantsSorted (param, variable, formatDuration, sortDirection = DESCENDING) {
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            const params = {
                datasetUid: this.processId,
                limit: 10,
                sortBy: param,
                sortDirection,
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
            }
            apiRequest(Api().dataNavigator.variantsSorted(params))
                .then(({ rows: data }) => {
                    const dataByVariantId = data.map(({ variantId }) => variantId)
                    const dataByValue = data.map(({ value }) => value)

                    this[variable] = {
                        ...this[variable],
                        title: {
                            ...this[variable].title,
                            text: this.$t(`visualization.dashboard.sortBy.${param}`),
                        },
                        yAxis: {
                            name: this.$t(`visualization.dashboard.axisLabels.variantId`),
                            ...this[variable].yAxis,
                            data: sortDirection === DESCENDING ? dataByVariantId.reverse() : dataByVariantId,
                        },
                        xAxis: {
                            name: this.$t(`visualization.dashboard.axisLabels.${formatDuration ? 'duration' : 'frequency'}`),
                            axisLabel: {
                                formatter: value => (formatDuration ? this.formatDuration(value) : value),
                            },
                        },
                        series: this[variable].series.map(serie => ({
                            ...serie,
                            data: sortDirection === DESCENDING ? dataByValue.reverse() : dataByValue,
                        })),
                        tooltip: {
                            ...this[variable].tooltip,
                            formatter: ([{ name, value }]) => `${name}: ${formatDuration ? this.formatDuration(value) : value}`,
                        },
                    }
                })
                .catch(notifyError)
        },
        getVariantsOver () {
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            const params = {
                datasetUid: this.processId,
                sortDirection: DESCENDING,
                additionalDimensions: [{ type: 'DURATION', measurement: 'AVERAGE' }],
                baseDimension: { type: 'FREQUENCY' },
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
            }
            apiRequest(Api().dataNavigator.variantsOver(params))
                .then(({ rows: data }) => {
                    const formattedData = data.map(d => ({
                        duration: d.dimensions[0].value,
                        frequency: d.baseDimensionValue,
                        variantId: d.variantId,
                    }))
                    const sortedData = sortBy(formattedData, 'duration')

                    this.variantsOver = {
                        ...this.variantsOver,
                        title: {
                            ...this.variantsOver.title,
                            text: this.$t(`visualization.dashboard.variantsOver`),
                        },
                        series: {
                            ...this.variantsOver.series,
                            data: sortedData.map(({ duration, frequency, variantId }) => ({
                                value: [
                                    duration,
                                    frequency,
                                ],
                                name: variantId,
                            })),
                        },
                        yAxis: {
                            ...this.variantsOver.yAxis,
                            name: this.$t(`visualization.dashboard.axisLabels.frequency`),
                            axisLabel: {
                                formatter: value => this.formatFrequency(value),
                            },
                        },
                        xAxis: {
                            ...this.variantsOver.xAxis,
                            name: this.$t(`visualization.dashboard.axisLabels.duration`),
                            axisLabel: {
                                formatter: value => this.formatDuration(value),
                            },
                        },
                        tooltip: {
                            ...this.variantsOver.tooltip,
                            formatter: ([value]) => (value
                                ? `
                                    ${this.$t('visualization.dashboard.axisLabels.variantId')}: ${value.name}
                                    <br>
                                    ${this.$t('visualization.dashboard.axisLabels.frequency')}: ${this.formatFrequency(value.value[1])}
                                    <br>
                                    ${this.$t('visualization.dashboard.axisLabels.duration')}: ${this.formatDuration(value.value[0])}
                                    `
                                : ''),
                        },
                    }
                })
                .catch(notifyError)
        },
        getTransitionsSorted (param, variable, formatDuration) {
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)

            const params = {
                datasetUid: this.processId,
                limit: 10,
                sortBy: param,
                sortDirection: DESCENDING,
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
            }

            apiRequest(Api().dataNavigator.transitionsSorted(params))
                .then(({ rows: data }) => {
                    const seriesData = uniq(data.map(({ transition }) => [transition.source, transition.target]).flat())
                    const values = data.map(({ value }) => value)

                    this[variable] = {
                        ...this[variable],
                        title: {
                            ...this[variable].title,
                            text: this.$t(`visualization.dashboard.sortBy.${param}`),
                        },
                        xAxis: { // source
                            ...this[variable].xAxis,
                            data: seriesData,
                        },
                        yAxis: { // target
                            ...this[variable].yAxis,
                            data: seriesData,
                        },
                        series: this[variable].series.map(serie => ({
                            ...serie,
                            data: data.map(({ transition, value }) => ([
                                seriesData.indexOf(transition.source),
                                seriesData.indexOf(transition.target),
                                value,
                            ])),
                            label: {
                                show: true,
                                formatter: ({ dimensionNames, data: dimensionData }) => {
                                    const index = dimensionNames.indexOf('value')
                                    const value = dimensionData[index]
                                    return formatDuration ? this.formatDuration(value) : value
                                },
                            },
                        })),
                        visualMap: {
                            ...this[variable].visualMap,
                            min: Math.min(...values),
                            max: Math.max(...values) < 2 ? 10 : Math.max(...values),
                            formatter: value => (formatDuration ? this.formatDuration(value) : value),
                        },
                        tooltip: {
                            ...this[variable].tooltip,
                            formatter: ({ value }) => `
                            ${this.$t('visualization.dashboard.heatmap.source')}: ${seriesData[value[0]]}
                            <br/>
                            ${this.$t('visualization.dashboard.heatmap.target')}: ${seriesData[value[1]]}
                            <br/>
                            ${this.$t('visualization.dashboard.heatmap.value')}: ${formatDuration ? this.formatDuration(value[2]) : value[2]}
                            `,
                        },
                    }
                })
                .catch(notifyError)
        },
    },
}
</script>
<style lang="scss" scoped>
.PowerBIContainer {
    position: fixed;
    top: 45%;
    left: 50%;
    width:60vw;
    height: 5vh;
    transform: translate(-50%, -540%);
      z-index: 2003;
  }
  .PowerBIButton {
      margin-right: 7px;
  }
.DataNavigator {
    margin: 0 30px 16px;
    height: fit-content;
    overflow: auto;
}
.ButtonsContainer {
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ButtonGroupContainer {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.q-btn {
    min-width: fit-content;
}
.ChartsContainer {
    margin: 0 auto;
    width: fit-content;
    overflow: auto;
    max-height: 60vh;
    .chart {
        height: 400px;
        width: 600px;
        margin-bottom: 30px;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
    }
    &.Transitions .chart {
        width: 1000px;
        margin: 0 auto;
         & + .chart {
          margin-left: 0;
        }
    }
    .VariantsOver {
        width: 100%;
        min-width: 1000px;
        margin: 0 !important;
    }
}
.border-toggle {
        border: 1px solid $primary;
}
</style>
