<template>
    <div
        class="WorkingDays q-pa-md"
        :class="wizard ? 'WorkingDaysWizard' : 'WorkingDaysSettings'"
    >
        <div>
            <div class="text-subtitle1 text-weight-bold text-primary q-ml-lg q-mt-lg">
                {{ $t('visualization.settings.workingDays.name') }}
            </div>
            <div class="Section row justify-start q-px-md">
                <QToggle
                    v-model="enableWorkingDays"
                    checkedIcon="check"
                    color="green"
                    uncheckedIcon="clear"
                    class="text-primary text-subtitle1"
                    :label="$t('visualization.settings.workingDays.enableText')"
                    @update:model-value="workingDaysMood"
                />
            </div>
            <q-slide-transition>
                <div
                    v-if="enableWorkingDays"
                    class="InputContainer q-px-lg"
                >
                    <WText
                        class="mb-1"
                        color="dark"
                    >
                        {{ $t('visualization.settings.workingDays.description') }}
                    </WText>
                    <div class="Section Time q-gutter-sm row justify-start align-center">
                        <WText
                            class="mb-1"
                        >
                            {{ $t('visualization.settings.workingDays.workingHours') }}
                        </WText>
                        <QInput
                            v-model="start"
                            class="TimeInput col-3 align-center"
                            mask="time"
                            outlined
                            :disable="!enableWorkingDays"
                            :label="$t('visualization.settings.workingDays.start')"
                            :rules="['time']"
                            @click="openStartTimeModal=true"
                        >
                            <template #append>
                                <QIcon
                                    name="access_time"
                                    class="cursor-pointer self-center"
                                >
                                    <QPopup-proxy
                                        cover
                                        transitionShow="scale"
                                        transitionHide="scale"
                                    >
                                        <QTime
                                            v-model="start"
                                        >
                                            <div
                                                class="row items-center justify-end"
                                            >
                                                <QBtn
                                                    v-close-popup
                                                    :label="$t('visualization.filters.reset')"
                                                    color="primary"
                                                    flat
                                                    @click="start = undefined"
                                                />
                                            </div>
                                        </QTime>
                                    </QPopup-proxy>
                                </QIcon>
                            </template>
                        </QInput>
                        <QInput
                            v-model="end"
                            class="TimeInput col-3 align-center"
                            :label="$t('visualization.settings.workingDays.end')"
                            :disable="!enableWorkingDays"
                            outlined
                            mask="time"
                            :rules="['time']"
                            @click="openEndTimeModal=true"
                        >
                            <template #append>
                                <QIcon
                                    name="access_time"
                                    class="cursor-pointer self-center"
                                >
                                    <QPopup-proxy
                                        cover
                                        transitionShow="scale"
                                        transitionHide="scale"
                                    >
                                        <QTime
                                            v-model="end"
                                        >
                                            <div
                                                class="row items-center justify-end"
                                            >
                                                <QBtn
                                                    v-close-popup
                                                    :label="$t('visualization.filters.reset')"
                                                    color="primary"
                                                    flat
                                                    @click="end = undefined"
                                                />
                                            </div>
                                        </QTime>
                                    </QPopup-proxy>
                                </QIcon>
                            </template>
                        </QInput>
                    </div>
                    <div class="Section row justify-start">
                        <QToggle
                            v-model="excludeWeekends"
                            :disable="!enableWorkingDays"
                            color="primary"
                            class="text-primary text-subtitle2"
                            :label="$t('visualization.settings.workingDays.excludeWeekends')"
                            @update:model-value="handleInput"
                        />
                    </div>
                </div>
            </q-slide-transition>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'WorkingDaysSettings',
    props: {
        loading: VueTypes.bool.def(false),
        wizard: VueTypes.bool.def(false),
    },
    emits: ['WorkingDaysConfig'],
    data () {
        return {
            start: undefined,
            end: undefined,
            enableWorkingDays: false,
            excludeWeekends: false,
        }
    },
    watch: {
        start (newValue) {
            this.handleInput()
        },
        end (newValue) {
            this.handleInput()
        },
    },
    methods: {
        workingDaysMood (state) {
            if (!state) {
                this.start = undefined
                this.end = undefined
                this.excludeWeekends = false
            }
            this.handleInput()
        },
        handleInput () {
            const workingDays = {
                start: this.start,
                end: this.end,
                enableWorkingDays: this.enableWorkingDays,
                excludeWeekends: this.excludeWeekends,
            }
            console.log(workingDays)
            // this.$emit('WorkingDaysConfig', workingDays)
        },
    },

}
</script>
<style lang="scss" scoped>
.WorkingDaysSettings {
  min-height: 300px;
}
.WorkingDaysWizard {
  min-height: fit-content;
}
.WorkingDays {
  top: $header-height;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  z-index: 2002;
  background: white;
  border: solid 1px rgba($black, 0.2);
  box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
  border-radius: $border-radius;
    .WorkingDays {
        margin: 20px;
    }
    .OptionsGroup {
        gap: 10px;
    }
    .WText{
        margin-top: 20px;
    }

    .buttons {
        width: 80px;
    }

    .ButtonContainer {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }
    .Section {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .InputContainer {
        height: fit-content;
    }
    .Time {
        align-items: center;
        .TimeInput {
            margin-left: 4vw;
            margin-top: 0;
            padding: 0;
        }
    }
}
</style>
