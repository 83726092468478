<template>
    <div class="Step">
        <div
            class="Wrapper"
        >
            <q-inner-loading
                :showing="prueba"
                visible
            />
            <WorkingDays
                v-if="!prueba"
                :wizard="true"
                @workingDaysConfig="handleInput"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import WorkingDays from '@/screens/Project/Process/Settings/components/WorkingDays.vue'

export default {
    name: 'StepWorkingDays',
    components: {
        WorkingDays,
    },
    props: {
        modelValue: VueTypes.shape({
            workingDaysConfig: VueTypes.any.isRequired,
        }).loose,
        steps: VueTypes.any,
        isLoadingRelevant: VueTypes.bool.def(true),
    },
    emits: ['update:modelValue'],
    data () {
        return {
            prueba: this.isLoadingRelevant,
        }
    },
    watch: {
        isLoadingRelevant (newVal) {
            this.prueba = newVal
        },
    },
    methods: {
        handleInput (workingDaysConfig) {
            // this.$emit('update:modelValue', {
            //     ...this.modelValue,
            //     workingDaysConfig,
            // })
        },
        isValid () {
            const { form } = this.$refs
            return form ? form.isValid() : true
        },
    },
}
</script>
<style scoped lang="scss">

.Wrapper {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    min-height: 60%;
}
.Step {
    min-height: 500px;
}
.WLoading {
    min-height: 100px;
}
</style>
