<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <div
                    v-if="!mountedModal"
                >
                    <q-item
                        class="text-primary"
                    >
                        <q-item-section avatar>
                            <q-icon
                                name="edit"
                            />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label class="text-h6 text-weight-medium">
                                {{ $t('visualization.zoomer.fab') }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="q-px-lg Body">
                <q-expansion-item
                    v-if="!openAddGroup && !mountedModal"
                    dense
                    denseToggle
                    expandSeparator
                    icon="help"
                    headerClass="text-subtitle1 text-primary"
                    class="q-mb-md"
                    :label="$t('visualization.zoomer.labelInfo')"
                >
                    <q-banner
                        rounded
                        class="bg-grey-2 q-pa-md"
                    >
                        <span class="text-body2 text-justify">{{ $t('visualization.zoomer.info') }}</span>
                    </q-banner>
                </q-expansion-item>
                <div class="ActivitiesContainer">
                    <q-tabs
                        v-if="!openAddGroup && !mountedModal"
                        v-model="tab"
                        noCaps
                        dense
                        class="text-primary"
                    >
                        <q-tab
                            v-for="curTab in tabs"
                            :key="curTab.name"
                            :name="curTab.name"
                            :label="curTab.label"
                        />
                    </q-tabs>
                    <QTabPanels
                        v-if="!openAddGroup && !mountedModal"
                        v-model="tab"
                    >
                        <QTabPanel
                            class="SLATabs"
                            name="group"
                        >
                            <div class="row justify-between">
                                <QBtn
                                    color="primary"
                                    icon="add"
                                    noCaps
                                    menuAnchor="bottom end"
                                    :menuOffset="[65,0]"
                                    :label="$t('visualization.zoomer.addGroup')"
                                    @click="onOpenAddGroup"
                                />
                                <QBtn
                                    icon="delete_outline"
                                    noCaps
                                    color="primary"
                                    :label="$t('visualization.zoomer.removeGroup')"
                                    @click="handleDeleteGroups"
                                />
                            </div>
                            <div class="TableRow row">
                                <QTable
                                    class="Table"
                                    flat
                                    bordered
                                    hidePagination
                                    :rowsPerPageOptions="[0]"
                                    :rows="groupedActivities"
                                    :columns="activitiesColumns"
                                    rowKey="name"
                                    :selected.sync="selectedGroups"
                                    selection="multiple"
                                    @selection="updateSelectedGroups"
                                >
                                    <template #body-cell-name="props">
                                        <QTd :props="props">
                                            {{ props.row.name }}
                                            <QTooltip
                                                v-if="checkQtooltipVisible('variantName', props.row.name)"
                                                anchor="top start"
                                                self="center start"
                                                :offset="[-10,0]"
                                            >
                                                {{ props.row.name }}
                                            </QTooltip>
                                        </QTd>
                                    </template>
                                    <template #body-cell-included="props">
                                        <QTd :props="props">
                                            <div
                                                v-for="(activity, index) in props.row.activities"
                                                :key="index"
                                            >
                                                {{ activity }}
                                            </div>
                                            <QTooltip
                                                anchor="top start"
                                                self="center start"
                                                :offset="[-10,0]"
                                            >
                                                <div
                                                    v-for="(activity, index) in props.row.activities"
                                                    :key="index"
                                                >
                                                    {{ activity }}
                                                </div>
                                            </QTooltip>
                                        </QTd>
                                    </template>
                                    <template #no-data="{ message }">
                                        <div class="full-width row flex-center text-accent q-gutter-sm">
                                            <span>
                                                {{ message }}
                                            </span>
                                        </div>
                                    </template>
                                </QTable>
                            </div>
                            <div class="row justify-end mt-1">
                                <QBtn
                                    noCaps
                                    type="submit"
                                    color="primary"
                                    icon="done"
                                    :label="$t('visualization.zoomer.apply')"
                                    @click="saveZoomerSettings"
                                />
                            </div>
                        </QTabPanel>
                        <QTabPanel
                            class="SLATabs"
                            name="remove"
                        >
                            <div class="row justify-center">
                                <QSelect
                                    v-model="excludedActivities"
                                    :options="activitiesExcluded"
                                    dense
                                    useInput
                                    outlined
                                    hideSelected
                                    :multiple="true"
                                    optionValue="value"
                                    optionLabel="label"
                                >
                                    <template #option="scope">
                                        <QItem
                                            v-bind="scope.itemProps"
                                            style="pointer-events: none;"
                                        >
                                            <QItemSection>
                                                <QItemLabel v-html="scope.opt.label" />
                                            </QItemSection>
                                            <QItemSection side>
                                                <QToggle
                                                    v-model="scope.selected"
                                                    :val="scope.opt.value"
                                                    :disable="scope.opt.disable"
                                                    style="pointer-events: auto;"
                                                    @update:model-value="toggleExcludedActivity(scope.opt.value)"
                                                />
                                            </QItemSection>
                                        </QItem>
                                    </template>
                                </QSelect>
                                <div>
                                    <div
                                        v-for="(selected, index) in excludedActivities"
                                        :key="index"
                                    >
                                        <q-chip
                                            :label="selected"
                                            removable
                                            color="primary"
                                            textColor="white"
                                            @remove="deleteExcludedActIndexValue(index)"
                                        >
                                            {{ selected.label }}
                                        </q-chip>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-end mt-1">
                                <QBtn
                                    type="submit"
                                    color="primary"
                                    noCaps
                                    icon="done"
                                    :label="$t('visualization.zoomer.apply')"
                                    @click="saveZoomerSettings"
                                />
                            </div>
                        </QTabPanel>
                    </QTabPanels>
                    <transition
                        @enter="enterElement"
                        @leave="leaveElement"
                        @after-leave="afterLeaveElement"
                    >
                        <div
                            class="pl-1 pr-1 q-pa-md"
                        >
                            <q-card
                                v-if="openAddGroup"
                                class="flex-column q-pa-md q-ma-md"
                                flat
                                bordered
                            >
                                <q-card-section class="text-subtitle1 text-primary">
                                    {{ $t('visualization.zoomer.addGroup') }}
                                </q-card-section>
                                <div
                                    class="flex"
                                >
                                    <WText
                                        class="pl-1 mr-2"
                                        tag="p"
                                        weight="bold"
                                    >
                                        {{ $t('visualization.zoomer.labelGroup') }}
                                    </WText>
                                    <QInput
                                        v-model="groupName"
                                        :rules="[ val => /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)*$/.test(val) ]"
                                        :label="$t('visualization.zoomer.groupName')"
                                        stackLabel
                                        outlined
                                    />
                                </div>
                                <div
                                    class="flex"
                                >
                                    <WText
                                        class="pl-1 mr-2"
                                        tag="p"
                                        weight="bold"
                                    >
                                        {{ $t('visualization.zoomer.labelActivities') }}
                                    </WText>
                                    <div class="flex">
                                        <QSelect
                                            :modelValue="null"
                                            dense
                                            useInput
                                            outlined
                                            hideSelected
                                            multiple
                                            :label="$t('visualization.zoomer.selectActivities')"
                                            :options="activities"
                                        >
                                            <template #option="scope">
                                                <QItem
                                                    v-bind="scope.itemProps"
                                                    @click.stop="handleAddActivity(scope.opt)"
                                                >
                                                    <QItemSection>
                                                        <QItemLabel v-html="scope.opt.label" />
                                                    </QItemSection>
                                                    <QItemSection side>
                                                        <QToggle
                                                            v-model="toggledActivities[scope.opt.value]"
                                                            :val="scope.opt.value"
                                                            :disable="scope.opt.disable"
                                                            @update:modelValue="handleAddActivity(scope.opt)"
                                                        />
                                                    </QItemSection>
                                                </QItem>
                                            </template>
                                        </QSelect>
                                        <div>
                                            <div
                                                v-for="(selected, index) in addGroupSelectedActivities"
                                                :key="index"
                                            >
                                                <QChip
                                                    color="primary"
                                                    textColor="white"
                                                >
                                                    {{ selected }}
                                                </Qchip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-end mt-1">
                                    <q-btn
                                        class="q-mr-md"
                                        noCaps
                                        color="secondary"
                                        icon="arrow_back"
                                        :label="$t('visualization.zoomer.goBack')"
                                        @click="handleBack"
                                    />
                                    <QBtn
                                        noCaps
                                        type="submit"
                                        color="primary"
                                        icon="add_circle"
                                        :label="$t('visualization.zoomer.createGroup')"
                                        @click="saveGroupActivities"
                                    />
                                </div>
                            </q-card>
                        </div>
                    </transition>
                </div>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import anime from 'animejs'
import {
    QBtn,
    QTooltip,
} from 'quasar'
import VueTypes from 'vue-types'
import {
    Api,
    apiRequest,
    notifyError,
    notifySuccess,
} from '@/api'

const TABS = {
    GROUP: 'group',
    REMOVE: 'remove',
}

export default {
    name: 'ZoomerEditModal',
    components: {
        QBtn,
        QTooltip,
    },
    mixins: [],
    props: {
        values: VueTypes.object,
        settings: VueTypes.object,
    },
    emits: ['onSaveZoomerConfig'],
    data () {
        return {
            activities: [],
            activitiesExcluded: [],
            selectedGroups: [],
            groupedActivities: [],
            excludedActivities: [],
            selectedExcludedActivities: [],
            activitiesColumns: [
                {
                    name: 'name',
                    align: 'left',
                    label: this.$t('visualization.zoomer.labelGroup'),
                    field: 'groupName',
                    sortable: true,
                },
                {
                    name: 'included',
                    align: 'left',
                    label: this.$t('visualization.zoomer.labelActivities'),
                    field: 'activities',
                },
            ],
            openAddGroup: false,
            addGroupSelectedActivities: [],
            groupName: '',
            mountedModal: false,
            timeout: null,
            tab: TABS.GROUP,
            opened: false,
            toggledActivities: [],
        }
    },
    computed: {
        tabs () {
            return [
                { name: TABS.GROUP, label: this.$t('visualization.zoomer.groupTab') },
                { name: TABS.REMOVE, label: this.$t('visualization.zoomer.removeTab') },
            ]
        },
    },
    mounted () {
        this.getActivities()
        this.initializeGroupedActivities()
    },
    methods: {
        checkQtooltipVisible (column, qTooltipContent) {
            const span = document.createElement('span')
            span.innerText = qTooltipContent
            span.style.fontSize = '13px'
            span.style.visibility = 'hidden'
            span.style.position = 'absolute'
            span.style.top = '-9999px'
            document.body.appendChild(span)
            const width = span.offsetWidth
            document.body.removeChild(span)
            const maxContentWidth = this.getMaxContentWidth(column)
            return width > maxContentWidth
        },
        getMaxContentWidth (column) {
            let maxContentWidth = 0
            const pageWidth = window.innerWidth
            if (column === 'type') {
                if (pageWidth < 1600) {
                    maxContentWidth = 31
                } else if (pageWidth >= 1600 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * 0.02
                } else {
                    maxContentWidth = 61
                }
            } else if (column === 'elements') {
                if (pageWidth < 1600) {
                    maxContentWidth = 75
                } else if (pageWidth >= 1600 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * 0.05
                } else {
                    maxContentWidth = 126
                }
            } else if (column === 'sla') {
                if (pageWidth < 1600) {
                    maxContentWidth = 22
                } else if (pageWidth >= 1600 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * 0.015
                } else {
                    maxContentWidth = 47
                }
            } else if (column === 'variantName') {
                if (pageWidth < 1450) {
                    maxContentWidth = 176
                } else if (pageWidth >= 1450 && pageWidth <= 2000) {
                    maxContentWidth = pageWidth * (pageWidth * 0.01)
                } else {
                    maxContentWidth = 356
                }
            }
            return maxContentWidth
        },
        initializeGroupedActivities () {
            this.groupedActivities = this.settings?.zoomerConfig?.groupedActivities || []
            this.excludedActivities = this.settings?.zoomerConfig?.excludedActivities || []
        },
        getActivities () {
            const { processId } = this.$route.params
            const params = {}
            apiRequest(Api().datasets.activities({ datasetId: processId, params }))
                .then((data) => {
                    this.activities = data.map(activity => ({
                        label: activity.name,
                        value: activity.name,
                        disable: this.settings?.zoomerConfig?.groupedActivities?.some(group => group.activities.includes(activity.id)) ||
                                 this.settings?.zoomerConfig?.excludedActivities?.some(group => group.includes(activity.id)),
                    })).sort()
                    this.activities.forEach((act) => {
                        this.toggledActivities[act.value] = false
                    })
                    this.activitiesExcluded = data.map(activity => ({
                        label: activity.name,
                        value: activity.name,
                        disable: this.settings?.zoomerConfig?.groupedActivities?.some(group => group.activities.includes(activity.id)),
                    })).sort()
                })
                .catch(notifyError)
        },
        saveGroupActivities () {
            if (!/^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)*$/.test(this.groupName) ||
                !this.addGroupSelectedActivities || this.addGroupSelectedActivities.length === 0 ||
                this.settings?.zoomerConfig?.groupedActivities.some(group => group.name === this.groupName)) {
                return
            }
            this.groupedActivities.push({ name: this.groupName, activities: this.addGroupSelectedActivities })
            this.activities = this.activities.map(activity => ({
                ...activity,
                disable: this.addGroupSelectedActivities.includes(activity.label) ? true : activity.disable,
            })).sort()
            this.activitiesExcluded = this.activitiesExcluded.map(activity => ({
                ...activity,
                disable: this.addGroupSelectedActivities.includes(activity.label) ? true : activity.disable,
            })).sort()
            this.handleBack()
        },
        saveZoomerSettings () {
            const { processId } = this.$route.params
            apiRequest(Api().datasets.update({
                datasetId: processId,
                params: {
                    zoomerConfig: {
                        groupedActivities: this.groupedActivities,
                        excludedActivities: this.excludedActivities,
                    },
                },
            }))
                .then(() => {
                    notifySuccess(this.$t('visualization.zoomer.zoomerConfigCreatedNotification'))
                })
                .catch(notifyError)
                .finally(() => {
                    this.$emit('onSaveZoomerConfig')
                    this.close()
                })
        },
        handleDeleteGroups () {
            this.groupedActivities = this.groupedActivities.filter(row => !this.selectedGroups.includes(row))
            const disabledLabels = this.selectedGroups
                .flatMap(group => group.activities)
            this.activities = this.activities.map(activity => ({
                ...activity,
                disable: disabledLabels.includes(activity.label) ? false : activity.disable,
            })).sort()
            this.activitiesExcluded = this.activitiesExcluded.map(activity => ({
                ...activity,
                disable: disabledLabels.includes(activity.label) ? false : activity.disable,
            })).sort()
            disabledLabels.forEach((act) => {
                this.toggledActivities[act] = false
            })
            this.selectedGroups = []
        },
        handleAddActivity (value) {
            if (value.disable) {
                return
            }
            const index = this.addGroupSelectedActivities.indexOf(value.value)
            if (index === -1) {
                this.toggledActivities[value.value] = true
                this.addGroupSelectedActivities.push(value.value)
            } else {
                this.addGroupSelectedActivities.splice(index, 1)
                this.toggledActivities[value.value] = false
            }
        },
        handleAddGroupZoomer (value) {
            this.groupedActivities.push(value)
        },
        isSelected (value) {
            return this.addGroupSelectedActivities.includes(value)
        },
        onOpenAddGroup () {
            this.openAddGroup = true
        },
        updateSelectedGroups (selected) {
            this.selectedGroups = selected.rows
        },
        async restoreZoomerConfig (callback) {
            const { processId } = this.$route.params
            apiRequest(Api().datasets.update({
                datasetId: processId,
                params: {
                    zoomerConfig: {
                        groupedActivities: [],
                        excludedActivities: [],
                    },
                },
            }))
                .then(() => {
                    this.excludedActivities = []
                    this.groupedActivities = []
                    this.selectedExcludedActivities = []
                    this.selectedGroups = []
                    this.addGroupSelectedActivities = []
                    this.activities = this.activities.map(a => ({
                        ...a,
                        disable: false,
                    }))
                    this.activities.forEach((act) => {
                        this.toggledActivities[act.value] = false
                    })
                    this.activitiesExcluded = structuredClone(this.activities)
                    notifySuccess(this.$t('visualization.zoomer.restoreConfigNotification'))
                })
                .catch(notifyError)
                .finally(async () => {
                    if (callback) await callback()
                })
        },
        open () {
            this.opened = true
            this.mountedModal = false
        },
        close () {
            this.opened = false
        },
        handleBack () {
            this.openAddGroup = false
            this.addGroupSelectedActivities = []
            this.groupName = ''
        },
        enterElement (el, done) {
            this.mountedModal = true
            anime({
                targets: el,
                translateX: [400, 0],
                opacity: [0, 1],
                easing: 'easeInOutSine',
                duration: 700,
                complete: done,
            })
        },
        leaveElement (el, done) {
            anime({
                targets: el,
                translateX: [0, 400],
                opacity: [1, 0],
                easing: 'easeInOutSine',
                duration: 700,
                complete: done,
            })
        },
        afterLeaveElement () {
            this.mountedModal = false
        },
        toggleExcludedActivity (activity) {
            const index = this.excludedActivities.indexOf(activity)
            if (index === -1) {
                this.excludedActivities.push(activity)
            } else {
                this.excludedActivities.splice(index, 1)
            }
            this.updateAvailableActivitiesForGrouping()
        },
        deleteExcludedActIndexValue (index) {
            this.excludedActivities.splice(index, 1)
            this.updateAvailableActivitiesForGrouping()
        },
        updateAvailableActivitiesForGrouping () {
            this.activities = this.activities.map(activity => ({
                ...activity,
                disable: this.excludedActivities.includes(activity.label) ||
                         this.groupedActivities.flatMap(group => group.activities).includes(activity.label),
            })).sort()
        },
    },
}
</script>
<style lang="scss" scoped>
.q-dialog__inner--minimized > div {
    min-width: 60%;
}
.Card {
    overflow:hidden;
    flex-wrap:nowrap;
}
.Header {
    position:sticky;
    padding-bottom:0px;
}
.Body {
    overflow-y:auto;
}
.OptionsGroup {
    gap: 10px;
}
.WText{
    margin-top: 20px;
}
.Tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -40px;
    white-space: nowrap;
    &:deep(.q-panel) {
        max-width: 95%;
    }
}
.SLATabs {
    margin: 2%;
    width: 96%;
}
.ActivitiesContainer {
  overflow: auto;
  max-height:500px;
}
.TableRow {
    width:100%;
    max-height: 90%;
    margin-top: 30px;
    margin-bottom: 10px;
    .Table {
        flex-grow: 1;
        max-width: 100%;
        :deep(.q-table th) {
            font-weight: bold;
            font-size: 16px;
            background-color: lightgrey;
            text-align: left;
            position: sticky;
            top: 0;
            z-index: 99;
        }
        :deep(.q-table td) {
            max-width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        :deep(.q-table td.EditTD) {
            text-overflow: unset;
        }
    }
}
</style>
