<template>
    <QDialog
        v-model="open"
        class="grabbing"
        @hide="closeModal"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <q-icon
                    v-if="filters && selectedFilter.label"
                    clickable
                    name="arrow_back"
                    color="primary"
                    class="BackArrow"
                    size="24px"
                    @click="handleBack"
                />
                <WText
                    v-if="filters && selectedFilter.label"
                    tag="h3"
                    class="Tittle"
                    :size="16"
                    align="center"
                    weight="bold"
                >
                    {{ $t('visualization.filters.titleOpenFilter') }}
                </WText>
                <WText
                    v-else
                    tag="h3"
                    class="Tittle"
                    :size="16"
                    align="center"
                    weight="bold"
                >
                    {{ $t('visualization.filters.title') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="closeModal"
                />
            </q-card-section>
            <q-card-section class="Body">
                <div
                    v-if="filters && !selectedFilter.label && !mountedFilter"
                >
                    <FilterModalSection
                        :options="filters.ACTIVITIES"
                        :title="$t('visualization.filters.activities.title')"
                        @on-click-filter="handleClickFilter"
                    />
                    <FilterModalSection
                        :options="filters.ARCS"
                        :title="$t('visualization.filters.arcs.title')"
                        @on-click-filter="handleClickFilter"
                    />
                    <FilterModalSection
                        :options="filters.ATTRIBUTES"
                        :title="$t('visualization.filters.attributes.title')"
                        @on-click-filter="handleClickFilter"
                    />
                    <FilterModalSection
                        :options="filters.DATETIME"
                        :title="$t('visualization.filters.date.title')"
                        @on-click-filter="handleClickFilter"
                    />
                    <FilterModalSection
                        :options="filters.DURATION"
                        :title="$t('visualization.filters.duration.title')"
                        @on-click-filter="handleClickFilter"
                    />
                </div>
                <transition
                    @enter="enterElement"
                    @leave="leaveElement"
                    @after-leave="afterLeaveElement"
                >
                    <AttributesSection
                        v-if="filters && selectedFilter.id && selectedFilter.id.includes('ATTRIBUTE')"
                        :attributes="attributes"
                        :categories="activities"
                        :attributeValues="categoricalAttributes"
                        :selectedFilter="selectedFilter"
                        @on-save-attributes="handleSaveAttributes"
                    />
                    <div
                        v-else-if="filters && selectedFilter.label"
                        class="pl-1 pr-1 q-pa-md"
                    >
                        <q-card
                            class="FilterSection q-pa-md"
                            flat
                            bordered
                        >
                            <div class="ActivitiesDuration flex col-top-left pa-1">
                                <div class="flex col-top-left">
                                    <WText
                                        class="pb-1"
                                        align="center"
                                        weight="bold"
                                    >
                                        {{ selectedFilter.label }}
                                    </WText>
                                    <div class="DataContainer">
                                        <div class="selectStyle">
                                            <QSelect
                                                v-if="filterKey === FILTERS_TYPES.ACTIVITY_DURATION
                                                    || FILTERS_TYPES.ACTIVITY_STARTS_BEFORE === filterKey
                                                    || FILTERS_TYPES.ACTIVITY_STARTS_AFTER === filterKey
                                                    || FILTERS_TYPES.ACTIVITY_ENDS_BEFORE === filterKey
                                                    || FILTERS_TYPES.ACTIVITY_ENDS_AFTER === filterKey"
                                                v-model="filterValue.category"
                                                dense
                                                useInput
                                                outlined
                                                :multiple="isMultiple"
                                                behavior="menu"
                                                :label="$t('visualization.filters.activities.label')"
                                                :rules="[value => validators.required(value)]"
                                                :options="activityOpt"
                                                @filter="activityFilterFn"
                                                @update:modelValue="canSaveActivityArcFilter"
                                            />
                                            <QSelect
                                                v-if="filterKey === 'TRACE_STARTS_WITH_ACTIVITY' ||
                                                    filterKey === 'TRACE_ENDS_WITH_ACTIVITY' ||
                                                    filterKey === 'TRACE_NOT_STARTS_WITH_ACTIVITY' ||
                                                    filterKey === 'TRACE_NOT_ENDS_WITH_ACTIVITY' ||
                                                    'TRACE_CONTAINS_ACTIVITIES' === filterKey ||
                                                    'TRACE_NOT_CONTAINS_ACTIVITIES' === filterKey ||
                                                    filterKey === 'ACTIVITIES_HAPPEN_IN_ORDER'"
                                                v-model="filterValue.category"
                                                class="selectStyle"
                                                dense
                                                useInput
                                                outlined
                                                hideSelected
                                                :options="activityOpt"
                                                behavior="menu"
                                                :multiple="isMultiple"
                                                :label="$t('visualization.filters.activities.label')"
                                                :rules="[value => validators.required(value)]"
                                                @filter="activityFilterFn"
                                            >
                                                <template #option="scope">
                                                    <QItem
                                                        v-bind="scope.itemProps"
                                                        style="pointer-events: none;"
                                                    >
                                                        <QItemSection>
                                                            <QItemLabel v-html="scope.opt" />
                                                        </QItemSection>
                                                        <QItemSection side>
                                                            <QToggle
                                                                v-model="scope.selected"
                                                                :val="scope.opt"
                                                                style="pointer-events: auto;"
                                                                @update:modelValue="canSaveContainActivityFilter(scope.opt)"
                                                            />
                                                        </QItemSection>
                                                    </QItem>
                                                </template>
                                            </QSelect>
                                            <div
                                                v-if="FILTERS_TYPES.ACTIVITIES_DURATION === filterKey"
                                                class="flex py-1"
                                                style="width: max-content; column-gap: 10px;"
                                            >
                                                <QRadio
                                                    v-model="filterValue.activitiesDurationAlgorithm"
                                                    val="ActivityOccurrence"
                                                    :label="$t('visualization.filters.activities.activityOccurrence')"
                                                />
                                                <QRadio
                                                    v-model="filterValue.activitiesDurationAlgorithm"
                                                    val="PairRepetition"
                                                    :label="$t('visualization.filters.activities.pairRepetition')"
                                                />
                                            </div>
                                            <div
                                                v-if="FILTERS_TYPES.ACTIVITIES_DURATION === filterKey"
                                                class="flex"
                                            >
                                                <QSelect
                                                    v-if="filterValue.activitiesDurationAlgorithm == 'ActivityOccurrence' "
                                                    v-model="filterValue.sourceOccurrence"
                                                    dense
                                                    class="AttributeSelect"
                                                    outlined
                                                    behavior="menu"
                                                    :label="$t('visualization.filters.activitiesDuration.occurrenceLabel')"
                                                    :options="ATTRIBUTTE_TYPES.OCCURRENCE_ATTRIBUTE"
                                                />
                                                <QSelect
                                                    v-model="filterValue.activitySource"
                                                    dense
                                                    useInput
                                                    outlined
                                                    behavior="menu"
                                                    :label="$t('visualization.filters.activities.label')"
                                                    :rules="[value => validators.required(value)]"
                                                    :options="activityOpt"
                                                    @filter="activityFilterFn"
                                                />
                                            </div>
                                            <div
                                                v-if="FILTERS_TYPES.ACTIVITIES_DURATION === filterKey"
                                                class="flex"
                                                style="width: max-content;"
                                            >
                                                <QSelect
                                                    v-if="filterValue.activitiesDurationAlgorithm == 'ActivityOccurrence'"
                                                    v-model="filterValue.targetOccurrence"
                                                    dense
                                                    class="AttributeSelect"
                                                    outlined
                                                    behavior="menu"
                                                    :label="$t('visualization.filters.activitiesDuration.occurrenceLabel')"
                                                    :options="ATTRIBUTTE_TYPES.OCCURRENCE_ATTRIBUTE"
                                                />
                                                <QSelect
                                                    v-if="FILTERS_TYPES.ACTIVITIES_DURATION === filterKey"
                                                    v-model="filterValue.activityTarget"
                                                    dense
                                                    useInput
                                                    outlined
                                                    behavior="menu"
                                                    :label="$t('visualization.filters.activities.label')"
                                                    :rules="[value => validators.required(value)]"
                                                    :options="activityOpt"
                                                    @filter="activityFilterFn"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="( filterKey === 'TRACE_CONTAINS_ACTIVITIES' ||
                                                filterKey === 'TRACE_NOT_CONTAINS_ACTIVITIES' ||
                                                filterKey === 'TRACE_STARTS_WITH_ACTIVITY' ||
                                                filterKey === 'TRACE_ENDS_WITH_ACTIVITY' ||
                                                filterKey === 'TRACE_NOT_STARTS_WITH_ACTIVITY' ||
                                                filterKey === 'TRACE_NOT_ENDS_WITH_ACTIVITY' ||
                                                filterKey === 'ACTIVITIES_HAPPEN_IN_ORDER'
                                            ) && filterValue.category"
                                        >
                                            <div v-if="filterKey === 'ACTIVITIES_HAPPEN_IN_ORDER'">
                                                <draggable
                                                    v-model="filterValue.category"
                                                    itemKey="label"
                                                    ghostClass="ghost"
                                                    handle=".chip-handle"
                                                >
                                                    <template #item="{ element, index }">
                                                        <q-chip
                                                            removable
                                                            color="primary"
                                                            textColor="white"
                                                            class="q-ma-xs chip-handle"
                                                            @remove="deleteIndexValue(index, filterValue.category)"
                                                        >
                                                            <q-icon
                                                                name="open_with"
                                                                class="q-mr-sm"
                                                            />
                                                            {{ index }}. {{ element }}
                                                        </q-chip>
                                                    </template>
                                                </draggable>
                                            </div>
                                            <div
                                                v-for="(selected, index) in filterValue.category"
                                                v-else
                                                :key="index"
                                            >
                                                <q-chip
                                                    :label="selected"
                                                    removable
                                                    color="primary"
                                                    textColor="white"
                                                    @remove="deleteIndexValue(index, filterValue.category)"
                                                >
                                                    {{ selected.label }}
                                                </q-chip>
                                            </div>
                                        </div>
                                    </div>
                                    <div />
                                    <div
                                        class="flex"
                                    >
                                        <QSelect
                                            v-if="filterKey === FILTERS_TYPES.ARC_DURATION"
                                            v-model="filterValue.arc"
                                            dense
                                            useInput
                                            outlined
                                            :multiple="isMultiple"
                                            behavior="menu"
                                            :label="$t('visualization.filters.arcs.label')"
                                            :rules="[value => validators.required(value)]"
                                            :options="allArcFilteredOptions"
                                            @filter="arcFilterFn"
                                            @update:modelValue="canSaveActivityArcFilter"
                                        />
                                        <QSelect
                                            v-if="ARC_FILTERS.includes(filterKey)"
                                            v-model="filterValue.arc"
                                            dense
                                            useInput
                                            outlined
                                            hideSelected
                                            :multiple="isMultiple"
                                            behavior="menu"
                                            :label="$t('visualization.filters.arcs.label')"
                                            :rules="[value => validators.required(value)]"
                                            :options="allArcFilteredOptions"
                                            @filter="arcFilterFn"
                                        >
                                            <template #option="scope">
                                                <QItem
                                                    v-bind="scope.itemProps"
                                                    style="pointer-events: none;"
                                                >
                                                    <QItemSection>
                                                        <QItemLabel v-html="scope.opt" />
                                                    </QItemSection>
                                                    <QItemSection side>
                                                        <QToggle
                                                            v-model="scope.selected"
                                                            :val="scope.opt"
                                                            style="pointer-events: auto;"
                                                            @update:modelValue="canSaveOcurringArcFilter(scope.opt)"
                                                        />
                                                    </QItemSection>
                                                </QItem>
                                            </template>
                                        </QSelect>
                                        <div
                                            v-if="(filterKey === 'TRACE_CONTAINS_ARCS' ||
                                                filterKey === 'TRACE_NOT_CONTAINS_ARCS') && filterValue.arc"
                                        >
                                            <div
                                                v-for="(selected, index) in filterValue.arc"
                                                :key="index"
                                            >
                                                <q-chip
                                                    :label="selected"
                                                    removable
                                                    color="primary"
                                                    textColor="white"
                                                    @remove="deleteIndexValue(index, filterValue.arc)"
                                                >
                                                    {{ selected.label }}
                                                </q-chip>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div
                                            v-if="DATE_FILTERS.includes(filterKey) || FILTERS_TYPES.ACTIVITY_ENDS_BEFORE === filterKey"
                                            class="flex row-left-center flex-nowrap"
                                        >
                                            <QDate
                                                v-model="filterValue.date"
                                                mask="YYYY-MM-DD HH:mm:ss"
                                                @update:modelValue="canSaveTraceDateFilter"
                                            />
                                            <QTime
                                                v-model="filterValue.date"
                                                class="ml-1"
                                                withSeconds
                                                mask="YYYY-MM-DD HH:mm:ss"
                                            />
                                        </div>
                                        <QSelect
                                            v-if="DURATION_FILTERS.some((f) => f.id === filterKey)"
                                            ref="constraints"
                                            v-model="filterValue.constraints"
                                            dense
                                            style="margin-right: 1rem;"
                                            outlined
                                            behavior="menu"
                                            :label="$t('visualization.filters.constraints.label')"
                                            :options="ATTRIBUTTE_TYPES.QUANTITY_ATTRIBUTE"
                                        />
                                        <div
                                            v-if="DURATION_FILTERS.some((f) => f.id === filterKey)"
                                            class="row"
                                        >
                                            <QInput
                                                ref="durationValue"
                                                v-model="selectedDurationValue"
                                                :label="$t('visualization.filters.duration.label')"
                                                type="number"
                                                :min="1"
                                                class="AttributeSelect"
                                                :rules="[value => value >= 1 || $t('booster.alerts.definition.createScreen.triggerConditions.minValue')]"
                                                stackLabel
                                                dense
                                                outlined
                                            />
                                            <QSelect
                                                ref="durationUnit"
                                                v-model="selectedDurationUnit"
                                                dense
                                                outlined
                                                stackLabel
                                                :label="$t('booster.alerts.definition.createScreen.triggerConditions.selectUnit')"
                                                :options="timeUnits"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="filterValue.activitiesDurationAlgorithm == 'ActivityOccurrence' &&
                                        FILTERS_TYPES.ACTIVITIES_DURATION === filterKey"
                                    class="FilterDescription"
                                >
                                    {{ $t('visualization.filters.activities.activityOccurrenceDescription') }}
                                </div>
                                <div
                                    v-if="filterValue.activitiesDurationAlgorithm == 'PairRepetition' &&
                                        FILTERS_TYPES.ACTIVITIES_DURATION === filterKey"
                                    class="FilterDescription"
                                >
                                    {{ $t('visualization.filters.activities.pairRepetitionDescription') }}
                                </div>
                            </div>
                            <div class="SaveButton">
                                <QBtn
                                    color="primary"
                                    noCaps
                                    :label="$t('visualization.filters.add')"
                                    @click="handleSaveFilter"
                                />
                            </div>
                        </q-card>
                    </div>
                </transition>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import VueTypes from 'vue-types'
import groupBy from 'lodash/groupBy'
import anime from 'animejs'
import moment from 'moment'
import draggable from "vuedraggable"
import { Api, apiRequest, notifyError } from '@/api'
import { validatorsMixin, filtersStorageMixin } from '@/mixins'
import FilterModalSection from './components/FilterModalSection.vue'
import AttributesSection from './components/AttributesSection.vue'
import {
    ACTIVITY_FILTERS, DATE_FILTERS, FILTERS_TYPES, ATTRIBUTTE_TYPES, ARC_FILTERS,
} from './filtersEntity'

export default {
    name: 'FilterModal',
    components: { FilterModalSection, AttributesSection, draggable },
    mixins: [validatorsMixin, filtersStorageMixin],
    props: {
        isOpen: VueTypes.bool.def(false),
        attributes: VueTypes.array,
        categoricalAttributes: VueTypes.object,
        processId: VueTypes.oneOfType([String, Number]),
        info: VueTypes.object,
    },
    emits: ['onSaveFilter', 'close'],
    data () {
        return {
            canSaveFilter: false,
            filters: undefined,
            selectedFilter: {},
            filterKey: '',
            mountedFilter: false,
            open: false,
            filterValue: {
                category: null,
                date: '',
                duration: undefined,
                constraints: ATTRIBUTTE_TYPES.QUANTITY_ATTRIBUTE()[1],
                arc: null,
                variant: null,
                loopName: null,
                activitySource: null,
                sourceOccurrence: null,
                activityTarget: null,
                activityOccurrence: null,
                activitiesDurationAlgorithm: 'ActivityOccurrence',
                nvariants: null,
            },
            activities: [],
            selectedActivities: [],
            selectedArcs: [],
            startingActivityOptions: [],
            endingActivityOptions: [],
            activityTargetOptions: [],
            allFilteredActivityOptions: [],
            arcs: undefined,
            allArcFilteredOptions: [],
            timeout: null,
            selectedDurationValue: undefined,
            selectedDurationUnit: {
                value: 's',
                label: this.$t('booster.alerts.definition.timeUnit.seconds'),
            },
        }
    },
    computed: {
        timeUnits () {
            return [{
                value: 's',
                label: this.$t('booster.alerts.definition.timeUnit.seconds'),
            },
            {
                value: 'm',
                label: this.$t('booster.alerts.definition.timeUnit.minutes'),
            },
            {
                value: 'h',
                label: this.$t('booster.alerts.definition.timeUnit.hours'),
            },
            {
                value: 'd',
                label: this.$t('booster.alerts.definition.timeUnit.days'),
            },
            ]
        },
        activityOpt () {
            if (this.filterKey === 'TRACE_STARTS_WITH_ACTIVITY' ||
                this.filterKey === 'TRACE_NOT_STARTS_WITH_ACTIVITY'
            ) {
                return this.startingActivityOptions
            } if (this.filterKey === 'TRACE_ENDS_WITH_ACTIVITY' ||
                this.filterKey === 'TRACE_NOT_ENDS_WITH_ACTIVITY'
            ) {
                return this.endingActivityOptions
            }
            return this.allFilteredActivityOptions
        },
        variants () {
            return this.info.variants
        },
        isMultiple () {
            const { selectedFilter } = this
            return !([
                FILTERS_TYPES.ACTIVITY_DURATION,
                FILTERS_TYPES.ARC_DURATION,
                FILTERS_TYPES.ACTIVITIES_DURATION,
                FILTERS_TYPES.ACTIVITY_STARTS_BEFORE,
                FILTERS_TYPES.ACTIVITY_STARTS_AFTER,
                FILTERS_TYPES.ACTIVITY_ENDS_BEFORE,
                FILTERS_TYPES.ACTIVITY_ENDS_AFTER,
            ].includes(selectedFilter.id))
        },
    },
    watch: {
        isOpen (open) {
            this.open = open
            if (open) {
                this.getFilters()
                this.mountedFilter = false
            }
        },
        variants (newVal) {
            if (newVal !== undefined) {
                this.getSplitFilteredActivities(newVal)
            }
        },
        visualizationFilters () {
            this.getFilters()
            if (this.variants !== undefined) {
                this.getSplitFilteredActivities(this.variants)
            }
        },
        generalOperator () {
            this.getFilters()
            if (this.variants !== undefined) {
                this.getSplitFilteredActivities(this.variants)
            }
        },
        selectedDurationValue: 'updateDuration',
        selectedDurationUnit: 'updateDuration',
    },
    beforeMount () {
        this.ATTRIBUTTE_TYPES = {
            CATEGORICAL_ATTRIBUTE: ATTRIBUTTE_TYPES.CATEGORICAL_ATTRIBUTE(),
            BOOLEAN_ATTRIBUTE: ATTRIBUTTE_TYPES.BOOLEAN_ATTRIBUTE(),
            NUMERIC_ATTRIBUTE: ATTRIBUTTE_TYPES.NUMERIC_ATTRIBUTE(),
            DATETIME_ATTRIBUTE: ATTRIBUTTE_TYPES.DATETIME_ATTRIBUTE(),
            QUANTITY_ATTRIBUTE: ATTRIBUTTE_TYPES.QUANTITY_ATTRIBUTE(),
            OCCURRENCE_ATTRIBUTE: ATTRIBUTTE_TYPES.OCCURRENCE_ATTRIBUTE(),
        }
        this.ACTIVITY_FILTERS = ACTIVITY_FILTERS
        this.ARC_FILTERS = ARC_FILTERS
        this.FILTERS_TYPES = FILTERS_TYPES
        this.DURATION_FILTERS = [
            {
                label: this.$t('visualization.filters.duration.filters.trace'),
                type: 'DURATION',
                id: 'TRACE_DURATION',
                options: { GREATER_THAN: 'TRACE_DURATION_GREATER_THAN', LESSER_THAN: 'TRACE_DURATION_LESS_THAN' },
            },
            {
                label: this.$t('visualization.filters.duration.filters.activity'),
                type: 'DURATION',
                id: 'ACTIVITY_DURATION',
                options: { GREATER_THAN: 'ACTIVITY_DURATION_GREATER_THAN', LESSER_THAN: 'ACTIVITY_DURATION_LESS_THAN' },
            },
            {
                label: this.$t('visualization.filters.duration.filters.activities'),
                type: 'DURATION',
                id: 'ACTIVITIES_DURATION',
                options: {
                    GREATER_THAN: 'ACTIVITIES_DURATION_GREATER_THAN',
                    LESSER_THAN: 'ACTIVITIES_DURATION_LESS_THAN',
                    LOOP_GREATER_THAN: 'ACTIVITIES_DURATION_LOOP_GREATER_THAN',
                    LOOP_LESSER_THAN: 'ACTIVITIES_DURATION_LOOP_LESS_THAN',
                },
            },
            {
                label: this.$t('visualization.filters.duration.filters.arc'),
                type: 'DURATION',
                id: 'ARC_DURATION',
                options: { GREATER_THAN: 'ARC_DURATION_GREATER_THAN', LESSER_THAN: 'ARC_DURATION_LESSER_THAN' },
            },
        ]
        this.DATE_FILTERS = DATE_FILTERS
        this.ATTR_FILTERS = [
            {
                label: this.$t('visualization.filters.attributes.filters.numericalField'), type: 'ATTRIBUTE', id: 'NUMERIC_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.ctgField'), type: 'ATTRIBUTE', id: 'CATEGORICAL_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.booleanField'), type: 'ATTRIBUTE', id: 'BOOLEAN_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.dateTimeField'), type: 'ATTRIBUTE', id: 'DATETIME_ATTRIBUTE',
            },
        ]
    },
    mounted () {
        this.getFilters()
        this.listenFilterByVariant()
        this.listenFilterByLoop()
        this.listenFilterByNVariants()
    },
    methods: {
        async getFilters () {
            apiRequest(Api().visualizations.filters())
                .then((res) => {
                    const filtersIds = (this.visualizationFilters || []).map(({ id }) => id)
                    const sanitizedFilters = this.formatFilters(res)
                    const formattedFilters = sanitizedFilters.map(filter => (filtersIds.includes(filter.id) && !filter.repeatable ? { ...filter, readOnly: true } : filter))
                    const filtersByType = groupBy(formattedFilters, 'kind')
                    this.filters = { ...filtersByType, ATTRIBUTES: this.ATTR_FILTERS, DURATION: this.DURATION_FILTERS }
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },

        async getSplitFilteredActivities (variants) {
            const { processId } = this.$route.params
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(this.visualizationFilters)
            const arcs = true
            const params = {
                filters,
                arcs,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
            }
            apiRequest(Api().visualizations.splitFilteredActivities({ processId, params }))
                .then((data) => {
                    this.startingActivityOptions = (data.firstActivities).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                    this.endingActivityOptions = (data.lastActivities).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                    this.activities = (data.allActivities).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                    this.selectedActivities = this.filterValue.category ? this.filterValue.category : []
                    this.arcs = data.allArcs.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                    this.selectedArcs = this.filterValue.arc ? this.filterValue.arc : []
                    this.allArcFilteredOptions = data.allArcs
                })
                .catch(notifyError)
        },
        listenFilterByVariant () {
            this.$eventBus.on('filterByVariant', (variant) => {
                this.filterValue.variant = variant
                this.filterKey = 'TRACE_IS_IN_VARIANTS'
                this.selectedFilter.id = 'TRACE_IS_IN_VARIANTS'
                this.canSaveActivityArcFilter(this.filterValue.variant)
                this.handleSaveFilter()
            })
        },
        listenFilterByNVariants () {
            this.$eventBus.on('filterByNVariants', (value) => {
                this.filterValue.nvariants = value
                this.filterKey = 'TRACE_IS_IN_N_VARIANTS'
                this.selectedFilter.id = 'TRACE_IS_IN_N_VARIANTS'
                this.canSaveActivityArcFilter(this.filterValue.nvariants)
                this.handleSaveFilter()
            })
        },
        listenFilterByLoop () {
            this.$eventBus.on('filterByLoop', (loopVariants) => {
                this.filterValue.variant = loopVariants.variant
                this.filterKey = 'TRACE_IS_IN_LOOP_VARIANTS'
                this.selectedFilter.id = 'TRACE_IS_IN_LOOP_VARIANTS'
                this.filterValue.loopName = loopVariants.loopName
                this.canSaveActivityArcFilter(this.filterValue.variant)
                this.handleSaveFilter()
            })
        },
        arcFilterFn (val, update) {
            update(() => {
                const newValue = val.toLowerCase()
                this.allArcFilteredOptions = this.arcs.filter(v => v.toLowerCase().indexOf(newValue) > -1)
            })
        },
        activityFilterFn (val, update) {
            update(() => {
                const newValue = val.toLowerCase()
                this.allFilteredActivityOptions = this.activities.filter(v => v.toLowerCase().indexOf(newValue) > -1)
            })
        },
        formatFilters (filters = []) {
            return filters.map(({
                id, label, kind, repeatable,
            }) => ({
                kind, id, label, repeatable,
            }))
        },
        handleClickFilter (filter) {
            this.selectedFilter = filter
            this.filterKey = filter.id
        },
        handleSaveFilter () {
            const customDurationFilter = this.DURATION_FILTERS.find(f => f.id === this.selectedFilter.id)
            if (this.selectedFilter.id === 'ACTIVITIES_DURATION' &&
                (!this.filterValue?.activitySource ||
                 !this.filterValue?.activityTarget)) {
                return
            }

            if (this.filterKey === FILTERS_TYPES.ACTIVITIES_DURATION) {
                if (this.filterValue.activitiesDurationAlgorithm === 'ActivityOccurrence' &&
                    (!this.filterValue?.sourceOccurrence ||
                    !this.filterValue?.targetOccurrence)) {
                    return
                }
            }
            if (this.filterKey === FILTERS_TYPES.ARC_DURATION && !this.filterValue.arc) {
                return
            }
            if (this.filterKey === FILTERS_TYPES.ACTIVITY_DURATION && !this.filterValue.category) {
                return
            }
            if (customDurationFilter) {
                const quantityAttribute = this.filterValue.constraints.value
                const filterId = this.filterValue.activitiesDurationAlgorithm === 'ActivityOccurrence'
                    ? customDurationFilter.options[quantityAttribute]
                    : customDurationFilter.options[`LOOP_${quantityAttribute}`]

                const regexISODuration = /^P(?:(\d+Y)?(\d+M)?(\d+D)?)?(T(?:(\d+H)?(\d+M)?(\d+S)?)?)?$/
                if (!regexISODuration.test(this.filterValue?.duration)) {
                    return
                }
                this.$emit('onSaveFilter', { ...this.selectedFilter, id: filterId, type: this.filterValue })
            } else if (this.canSaveFilter) {
                this.$emit('onSaveFilter', { ...this.selectedFilter, type: this.filterValue })
            }
            this.resetFilter()
            this.getFilters()
        },
        handleSaveAttributes (attributes) {
            this.$emit('onSaveFilter', { attributes, type: this.selectedFilter.id })
            this.resetFilter()
        },
        handleBack () {
            this.resetFilter()
        },
        resetFilter () {
            this.canSaveFilter = false
            this.selectedFilter = {}
            this.selectedActivities = []
            this.selectedArcs = []
            this.filterKey = ''
            this.selectedDurationValue = undefined
            this.selectedDurationUnit = {
                value: 's',
                label: this.$t('booster.alerts.definition.timeUnit.seconds'),
            }
            this.filterValue = {
                category: null,
                date: '',
                duration: undefined,
                constraints: ATTRIBUTTE_TYPES.QUANTITY_ATTRIBUTE()[1],
                arc: null,
                variant: null,
                loopName: null,
                activitySource: null,
                sourceOccurrence: null,
                activityTarget: null,
                activityOccurrence: null,
                activitiesDurationAlgorithm: 'ActivityOccurrence',
            }
        },
        enterElement (el, done) {
            this.mountedFilter = true
            anime({
                targets: el,
                translateX: [400, 0],
                opacity: [0, 1],
                easing: 'easeInOutSine',
                duration: 700,
                complete: done,
            })
        },
        leaveElement (el, done) {
            anime({
                targets: el,
                translateX: [0, 400],
                opacity: [1, 0],
                easing: 'easeInOutSine',
                duration: 700,
                complete: done,
            })
        },
        beforeDestroy () {
            clearTimeout(this.timeout)
        },
        afterLeaveElement () {
            this.mountedFilter = false
        },
        deleteIndexValue (index, filterValuesArray) {
            filterValuesArray.splice(index, 1)
            this.canSaveActivityArcFilter(filterValuesArray)
        },
        updateDuration () {
            this.filterValue.duration = moment.duration(this.selectedDurationValue, this.selectedDurationUnit?.value).toISOString()
        },
        closeModal () {
            this.$emit('close')
            this.resetFilter()
            this.mountedFilter = false
        },
        canSaveContainActivityFilter (value) {
            if (!this.filterValue.category) {
                this.filterValue.category = []
            }
            if (!this.filterValue.category.includes(value)) {
                this.filterValue.category.push(value)
            } else {
                const index = this.filterValue.category.indexOf(value)
                if (index !== -1) {
                    this.filterValue.category.splice(index, 1)
                }
            }
            this.selectedActivities = this.filterValue.category
            this.canSaveFilter = this.filterValue.category.length > 0
        },
        canSaveOcurringArcFilter (value) {
            if (!this.filterValue.arc) {
                this.filterValue.arc = []
            }
            if (!this.filterValue.arc.includes(value)) {
                this.filterValue.arc.push(value)
            } else {
                const index = this.filterValue.arc.indexOf(value)
                if (index !== -1) {
                    this.filterValue.arc.splice(index, 1)
                }
            }
            this.selectedArcs = this.filterValue.arc
            this.canSaveFilter = this.filterValue.arc.length > 0
        },
        canSaveActivityArcFilter (value) {
            if (value) {
                const checkArray = Array.isArray(value)
                if (checkArray && !value.length) {
                    this.canSaveFilter = false
                } else {
                    this.canSaveFilter = true
                }
            } else {
                this.canSaveFilter = false
            }
        },
        canSaveTraceDateFilter (value) {
            this.canSaveFilter = value ?? false
        },
    },
}
</script>
<style lang="scss" scoped>
.FilterDescription {
    width: 25% !important;
    font-size: 14px;
}
.ActivitiesDuration {
    justify-content: space-between;
    flex-direction: row;
    column-gap: 200px;
}
.DataContainer{
    display:flex;
    flex-direction: row;
}
.selectStyle{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Permite desplazamiento horizontal */
    min-width: 300px;
    max-width: 500px;
}
.Tittle {
    margin-bottom: 0;
}
.AttributeSelect {
    width: 7rem;
    margin-right: 1rem;
}
.SaveButton {
    display: flex;
    justify-content: flex-end;
}
.q-dialog__inner--minimized > div {
    min-width: 70%;
}
.Card {
    overflow:hidden;
    flex-wrap:nowrap;
}
.Header {
    position:sticky;
    padding-bottom:0px;
}
.Body {
    overflow-y:auto;
    /* cursor: var(--cursor-style); */
}
/*
.grabbing {
  cursor: grabbing;
}
*/
.ghost {
  opacity: 0.5;
}

.chip-handle {
    cursor: grab;
}

.chip-handle:active {
  cursor: grabbing;
}

</style>
