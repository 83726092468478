<template>
    <QDialog
        v-model="opened"
        @hide="close"
    >
        <QCard class="flex column Card">
            <q-card-section class="row q-pa-lg items-center Header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ $t('projects.modals.upload.title') }}
                </WText>
                <q-space />
                <q-btn
                    v-close-popup
                    icon="close"
                    flat
                    round
                    dense
                    @click="close"
                />
            </q-card-section>
            <q-card-section class="Body q-pt-none q-px-lg">
                <div class="Wrapper">
                    <div>{{ $t('projects.modals.upload.text') }}</div>
                    <QUploader
                        v-if="token"
                        flat
                        bordered
                        accept=".csv"
                        sendRaw
                        color="grey-1"
                        textColor="primary"
                        class="Uploader mt-1 mb-0-5"
                        :label="$t('actions.uploadFile')"
                        :class="{ error, length: !!length, uploaded: isUploaded }"
                        autoUpload
                        :headers="headers"
                        :method="method"
                        :multiple="false"
                        :url="url"
                        @added="handleAdd"
                        @removed="handleRemove"
                        @start="handleStarted"
                        @finish="handleFinished"
                        @uploaded="handleSuccess"
                    />
                    <WText
                        v-if="error"
                        :size="12"
                        color="red"
                    >
                        {{ $t('validation.required', { attribute: $t('general.file') }) }}
                    </WText>
                </div>
            </q-card-section>
        </QCard>
    </QDialog>
</template>

<script>
import { getAuthorization, Api, notifyError, notifySuccess, apiRequest } from '@/api'
import Config from '@/config'

export default {
    name: 'Upload',
    components: {},
    emits: ['upload-action'],
    data () {
        return {
            method: 'POST',
            token: undefined,
            datasetUid: '',
            opened: false,
            length: 0,
            isInvalid: false,
            isLoading: false,
            isUploaded: false,
        }
    },
    computed: {
        label () {
            return this.$t(`actions.${this.isUploaded ? `close` : `cancel`}`)
        },
        url () {
            const { baseURL } = Config.api
            const { token } = this
            return `${baseURL}/uploads/datasets/update/${this.datasetUid}/${token}`
        },
        error () {
            return this.isInvalid || (this.isInvalid && !this.length)
        },
    },
    methods: {
        headers (files) {
            const { name } = files[0]

            return [
                { name: 'Authorization', value: getAuthorization() },
                { name: 'Content-Type', value: 'application/octet-stream' },
                { name: 'X-File-Name', value: name },
            ]
        },
        open (datasetUid) {
            this.opened = true
            this.datasetUid = datasetUid
            this.generateToken()
        },
        close () {
            this.opened = false
            this.isInvalid = false
            this.isLoading = false
            this.isUploaded = false
        },
        generateToken () {
            apiRequest(Api().datasets.generateUploadToken({ datasetId: this.datasetUid }))
                .then(({ token }) => { this.token = token })
                .catch(notifyError)
        },
        handleAdd () {
            this.length += 1
        },
        handleRemove () {
            this.length -= 1
        },
        handleStarted () {
            this.isInvalid = false
            this.isLoading = true
        },
        handleFinished () {
            this.isLoading = false
        },
        handleSuccess () {
            this.isUploaded = true
            this.finishUpdate(this.datasetUid)
        },
        finishUpdate (datasetUid) {
            apiRequest(Api().datasets.finishUpdate({ datasetId: datasetUid }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.updated.text'))
                    this.$emit('finishUpdate', this.datasetUid)
                    this.opened = false
                    this.isInvalid = false
                    this.isLoading = false
                    this.isUploaded = false
                })
                .catch((e) => {
                    notifyError(e)
                })
        },
    },
}
</script>
<style scoped lang="scss">
.Wrapper {
    margin: 30px 0;
}
.Uploader {
    width: 100%;

    &:deep() {
        .q-uploader__header {
            padding: 5px 10px;

            // Hide list button in quasar component
            .q-uploader__header-content a:first-child {
                display: none;
            }
        }

        .q-uploader__list {
            min-height: 0;
            padding: 0;
        }
        .q-uploader__file-header {
            padding: 10px;
        }
    }

    &.error:before {
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        border-radius: 4px;
        border: 2px solid $danger;
    }
    &.uploaded {
        &:deep() {
        .q-uploader__header {
            .q-uploader__header-content a:last-child {
                display: none;
            }
        }
        }
    }
    &.length {
        &:deep() {
            .q-uploader__list {
                padding: 15px;
            }
        }
    }
}
.Card .Body {
    max-width: 700px !important;
    min-width: 500px
}
.Header {
    padding-bottom: 0px;
}
</style>
