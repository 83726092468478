<template>
    <div>
        <ActivityPopUp
            style="z-index: 2000; position: absolute"
            :data="popupActivityData"
            :visible="isActivityPopupVisible"
            :isGroupActivities="isGroupActivities"
            :position="popupPosition"
            :tab="tab"
            @close="closeActivityPopup"
            @add-filter="handleAddFilterPopUp"
            @delete-filter="handleDeleteFilterPopUp"
        />
        <ArcsPopUp
            style="z-index: 2000; position: absolute"
            :data="popupArcData"
            :visible="isArcPopupVisible"
            :position="popupPosition"
            :tab="tab"
            @close="closeArcPopup"
            @add-filter="handleAddFilterPopUp"
            @delete-filter="handleDeleteFilterPopUp"
        />
        <div
            id="cy"
            class="Graph"
            style="width: auto;"
        />
        <div
            v-if="unrenderableChart"
            class="rounded-borders border-warning text-warning flex items-center unrenderable"
        >
            <q-icon
                name="warning"
                class="q-mr-sm"
            />
            <div>
                {{ $t('visualization.complexModel') }}
            </div>
        </div>
        <FrequencyIndicator
            ref="frequencyIndicator"
            class="frequency-indicator"
            :style="{ right: rightValue }"
            :validKnobValues="validKnobValues"
            @remove-activity="removeLessFreqActivities"
            @add-activity="addLessFreqActivities"
        />
        <q-inner-loading :showing="!data || data.activities?.length == 0 || Object.keys(data).length == 0 || variantsLoading" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import cytoscape from 'cytoscape'
// import dagre from 'cytoscape-dagre'
import klay from 'cytoscape-klay'
import svg from 'cytoscape-svg'
import { mediaQueryMixin } from '@/mixins'
import mount from '@/utils/mount'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import filtersStorageMixin from '../../mixins/filtersStorage.mixin'
import { Svg, nameFormatter } from './utils'
import { Arc, Activity } from './components'
import Utils from './components/utils'
import ActivityPopUp from './components/ActivityPopUp.vue'
import ArcsPopUp from './components/ArcsPopUp.vue'
import FrequencyIndicator from './components/FrequencyIndicator.vue'

const colorMap = {
    START: '#07f5b4',
    END: '#fad9dd',
}

const MAX_ACTIVITIES = 125
const MAX_ARCS = 1000

export default {
    name: 'GraphCyTo',
    components: {
        FrequencyIndicator,
        ActivityPopUp,
        ArcsPopUp,
    },
    mixins: [mediaQueryMixin, filtersStorageMixin],
    inject: ['App'],
    props: {
        data: VueTypes.shape({
            activities: VueTypes.any,
            arcs: VueTypes.any,
        }).loose,
        model: VueTypes.shape({
            values: VueTypes.string,
            statsValue: VueTypes.string,
            heatMapValue: VueTypes.string,
            directionValue: VueTypes.oneOf(['LR', 'TB']).def('TB'),
        }).loose,
        color: VueTypes.shape({
            activities: VueTypes.any,
            arcs: VueTypes.any,
        }).loose,
        parent: VueTypes.string,
        simple: VueTypes.bool.def(false),
        variantsLoading: VueTypes.bool.def(false),
        tab: VueTypes.string,
        settings: VueTypes.object,
    },
    emits: ['add-filter', 'delete-filter', 'zoomerIsVisible', 'resetZoomerIndicator'],
    data () {
        return {
            cy: null,
            popupActivityData: {},
            popupArcData: {},
            isActivityPopupVisible: false,
            isArcPopupVisible: false,
            isGroupActivities: false,
            popupPosition: { x: 0, y: 0 },
            selectedActivityPopup: null,
            selectedArcPopup: null,
            nodesPosition: {},
            minPath: [],
            totalPath: [],
            validKnobValues: [],
            percentageMap: {},
            actualPercentIndex: 0,
            newData: null,
            rightValue: '350px',
            screenContainerObserver: undefined,
            resizeTimeout: null,
            unrenderableChart: false,
        }
    },
    watch: {
        data () {
            this.cy.fit(this.cy.elements(), 50)
            this.newData = null
        },
    },

    created () {
        cytoscape.use(klay)
        cytoscape.use(svg)
        cytoscape.warnings(false) // Disable warnings in console
    },

    mounted () {
        this.$watch(vm => JSON.stringify([vm.data, vm.model?.values, vm.model?.statsValue, vm.model?.heatMapValue, vm.model?.directionValue]), () => {
            this.fetchGraph()
        }, { immediate: true })
        this.$watch(vm => JSON.stringify([vm.color, vm.model?.values, vm.model?.statsValue, vm.model?.heatMapValue, vm.model?.directionValue]),
            this.colorLoops, { immediate: true })
        document.addEventListener('click', this.handleClickOutside)
        this.screenContainerObserver = new ResizeObserver(this.onResize)
        setTimeout(() => {
            if (document.querySelector('.Screen')) {
                this.screenContainerObserver.observe(document.querySelector('.Screen'))
            }
        }, 50)
    },
    beforeUnmount () {
        document.removeEventListener('click', this.handleClickOutside)
        if (this.cy) {
            this.cy.off('tap', 'node')
            this.cy.off('tap', 'edge')
        }
    },
    methods: {
        onResize () {
            if (this.$refs.frequencyIndicator) {
                const screenElement = document.querySelector('.Screen')
                if (screenElement && screenElement.scrollHeight > screenElement.clientHeight) {
                    this.rightValue = '370px'
                } else {
                    this.rightValue = '350px'
                }
            }
        },
        fetchGraph (newData) {
            this.unrenderableChart = false
            if (newData) this.newData = this.formatData(newData)
            if (!this.renderGraph()) {
                this.unrenderableChart = true
                return
            }
            this.adjustNodeWithContent()
            this.adjustEdgeMarginContent()
            if (this.model && (this.data || this.newData)) this.enableLoopsAndOverlaps(this.newData || this.data, this.model)
            this.adjustSelfLoops()
            this.applyLayout()
            if (!newData) {
                this.$emit('zoomerIsVisible', true)
                this.checkZoomerActivities()
                this.$emit('resetZoomerIndicator')
            }
            this.cy.fit(this.cy.elements(), 50)
            if (this.handleSessionStorage('get')) this.applyNodesPositionByVariant(this.handleSessionStorage('get'))
            this.resetFreqIndictadorValues()
            this.calcMinPath()
        },
        renderGraph () {
            if (this.cy) {
                this.cy.destroy()
                this.cy = null
            }
            this.cy = cytoscape({
                container: document.getElementById('cy'),
                style: [
                    {
                        selector: 'node',
                        style: {
                            'text-valign': 'center',
                            'text-halign': 'center',
                            'background-color': '#fff',
                            'border-width': 'data(stroke)',
                            height: '70px',
                            shape: 'round-rectangle',
                            content: 'data(content)',
                            'text-wrap': 'wrap',
                            'font-family': 'Roboto',
                            'font-size': '18px',
                            'font-weight': 500,
                            'line-height': '0.75em',
                        },
                    },
                    {
                        selector: 'edge.normal',
                        style: {
                            'curve-style': 'bezier',
                            'control-point-distance': 200,
                            'text-outline-color': '#f00',
                            color: '#000',
                            'text-background-padding': 6,
                            width: 'data(strokeWidth)',
                            'target-arrow-shape': 'triangle',
                            'line-color': '#007bff',
                            'target-arrow-color': '#007bff',
                            label: 'data(label)',
                            'font-family': 'Roboto',
                            'text-wrap': 'wrap',
                            'text-max-width': 'none',
                            'text-justification': 'center',
                            'font-size': '18px',
                            'font-weight': '500',
                            'text-background-color': '#E9E9EF',
                            'text-background-opacity': 1,
                            'text-border-color': '#d0d0d0',
                            'text-border-width': 1,
                            'text-border-opacity': 1,
                            'text-background-shape': 'roundrectangle',
                            'text-margin-y': 10,
                        },
                    },
                    {
                        selector: 'edge.start',
                        style: {
                            'curve-style': 'bezier',
                            'control-point-distance': 200,
                            'line-style': 'dotted',
                            'line-color': '#01C38E',
                            'target-arrow-color': '#01C38E',
                            'target-arrow-shape': 'triangle',
                            'text-opacity': 0,
                            width: 4,
                        },
                    },
                    {
                        selector: 'edge.end',
                        style: {
                            'curve-style': 'bezier',
                            'control-point-distance': 200,
                            'line-style': 'dotted',
                            'line-color': '#FABFC5',
                            'target-arrow-color': '#FABFC5',
                            'target-arrow-shape': 'triangle',
                            'text-opacity': 0,
                            width: 4,
                        },
                    },
                    {
                        selector: '.highlighted-edge',
                        style: {
                            'text-background-color': '#b1d0f2',
                            'line-color': '#053f7d',
                            'target-arrow-color': '#053f7d',
                        },
                    },
                    {
                        selector: 'edge:loop',
                        style: {
                            'curve-style': 'bezier',
                            'loop-direction': '90deg',
                            'loop-sweep': '20deg',
                            'target-arrow-shape': 'triangle',
                        },
                    },
                ],
                wheelSensitivity: 0.25,
                maxZoom: 1.6,
                pixelRatio: 2,
            })
            // Charging the data if exists
            if (this.newData || this.data) {
                const { activities = [], arcs = [] } = this.newData || this.data
                if (activities.length < MAX_ACTIVITIES && arcs.length < MAX_ARCS) {
                    this.cy.add(this.renderActivities())
                    this.cy.add(this.renderArcs())
                } else {
                    return false
                }
            }
            this.listenNodeHover()
            this.listenEdgeHover()
            this.listenNodeMove()
            return true
        },

        applyLayout () {
            const options = this.adjustNodeAndEdgeByDirection(this.model.directionValue)
            const layout = this.cy.layout({
                name: 'klay',
                klay: options,
            })
            layout.run()
            this.adjustZoomByNodes()
            this.detectOverlappingNodes()
            this.setEndStartNodes(this.model.directionValue)
            this.saveInitialPosition()
            this.listenNodeHover()
            this.listenEdgeHover()
            this.openNodePopup()
            this.openArcPopup()
        },
        renderActivities () {
            const { activities = [], frequency } = this.newData || this.data
            const elements = []
            const maxFreq = Math.max(...activities.map(item => item.frequency))
            activities.forEach((item, index) => {
                const statistics = {
                    duration: item.duration,
                    frequency: this.App.numberLocationFormat(item.frequency),
                    ...(item.attribute ? { attribute: item.attribute } : {}),
                    heatMap: {
                        duration: 0.098,
                        frequency: item.frequency / frequency,
                    },
                }
                const strokeCalc = (item.frequency * 5) / maxFreq
                const maxStroke = 7
                const borderStroke = Math.min(strokeCalc < 1 ? 1.75 : strokeCalc, maxStroke)
                const propsData = {
                    id: `activity-${index}`,
                    name: item.name === 'START' || item.name === 'END' ? this.$t(`visualization.graph.${item.name}`) : item.name,
                    statistics,
                    model: this.model,
                    simple: this.simple,
                }
                const { vNode } = mount(Activity, { props: propsData })
                const value = this.valueFormatter(vNode.component.ctx.value)
                const info = `\t${propsData.name }\n\n${ value}`
                elements.push({
                    group: 'nodes',
                    data: {
                        id: item.name,
                        freq: item.frequency,
                        statistics,
                        model: this.model,
                        simple: this.simple,
                        content: info,
                        rank: index, // Now we rank by index, bcs its "always the same"
                        stroke: borderStroke,
                        modelData: {
                            variantCount: this.data?.variants,
                            variantUid: this.data?.variantId,
                            loopUid: this.data?.loopId,
                        },
                    },
                })
            })
            return elements
        },
        determineEdgeStyle (arc) {
            if (arc.sourceId === -1) {
                return 'start'
            }
            if (arc.targetId === -2) {
                return 'end'
            }
            return 'normal'
        },
        renderArcs () {
            const { arcs = [], frequency } = this.newData || this.data
            const maxFreq = Math.max(...arcs.map(item => item.frequency))
            const elements = []
            arcs.forEach((item, index) => {
                let cyclesEdge = 0
                const id = `arc-node-${index}`
                const labelId = `${id}-label`
                const maxStroke = 7
                const styleClass = this.determineEdgeStyle(item)
                const strokeSize = (item.frequency * 5) / maxFreq
                const stroke = Math.min(strokeSize < 1 ? 1.75 : strokeSize, maxStroke)
                const statistics = {
                    duration: item.duration,
                    frequency: this.App.numberLocationFormat(item.frequency),
                    overlap: true,
                    heatMap: {
                        duration: item.duration.avg / item.duration.max,
                        frequency: item.frequency / frequency,
                    },
                }
                const propsData = {
                    id: labelId, name: item.name, statistics, model: this.model, simple: this.simple,
                }
                const { vNode } = mount(Arc, { props: propsData })
                if (item.source === item.target) {
                    cyclesEdge = this.valueFormatter(vNode.component.ctx.value)
                }
                if (!this.checkInconsistencyArcs(item)) {
                    console.error('Inconsistency in data received from Arcs model ->', id, "doesn't exist in nodes")
                    return
                }
                elements.push({
                    group: 'edges',
                    classes: styleClass,
                    data: {
                        id: `arc-node-${nameFormatter(item.name)}`,
                        name: item.name,
                        freq: item.frequency,
                        source: item.source,
                        target: item.target,
                        label: this.valueFormatter(vNode.component.ctx.value),
                        statistics,
                        strokeWidth: stroke,
                        cycles: cyclesEdge,
                        modelData: {
                            variantCount: this.data?.variants,
                            variantUid: this.data?.variantId,
                            loopUid: this.data?.loopId,
                        },
                    },
                })
            })
            return elements
        },

        checkInconsistencyArcs (arc) {
            if (this.cy.getElementById(arc.source).length === 0 || this.cy.getElementById(arc.target).length === 0) {
                return false
            }
            return true
        },

        nameFormatter (name) {
            const sanitizedName = name.replace(/[^\w\s]/gi, '')
            return sanitizedName.replace(/\s/g, '-')
        },

        valueFormatter (value) {
            return value.replace(/\n/g, ' ')
                .replace(/<[^>]*>/g, '')
        },

        adjustNodeWithContent () {
            this.cy.nodes().forEach((node) => {
                const canvasTemp = document.createElement('canvas')
                const ctx = canvasTemp.getContext('2d')
                const counter = node.data('content')
                ctx.font = '17px roboto'
                const textWidth = ctx.measureText(counter).width
                node.style('width', `${textWidth - 2}px`)
                node.style('height', 'auto')
                node.style('padding', `2px`)
            })
        },

        adjustEdgeMarginContent () {
            if (this.model.directionValue === 'LR') return
            this.cy.edges().forEach((edge) => {
                const counter = edge.data('label')
                if (counter.length > 6) {
                    return edge.style('text-margin-x', 50)
                }
                return edge.style('text-margin-x', 35)
            })
        },

        adjustNodeAndEdgeByDirection (modelDirection) {
            const tinyGraph = this.cy.edges().length < 40
            const mediumGraph = this.cy.edges().length > 40 && this.cy.edges().length < 150
            const greatGraph = this.cy.edges().length > 150
            const options = {
                layoutHierarchy: true,
                thoroughness: 10,
                compactComponents: false,
                mergeEdges: true,
                separateConnectedComponents: true,
                edgeRouting: 'SPLINES',
                fixedAlignment: 'BALANCED',
                mergeHierarchyCrossingEdges: false,
                addUnnecessaryBendpoints: true,
                borderSpacing: 50,
                nodeLayering: 'INTERACTIVE',
            }
            if (modelDirection === 'TB') {
                options.direction = 'DOWN'
                if (tinyGraph) {
                    options.nodePlacement = 'INTERACTIVE'
                    options.edgeSpacingFactor = 0.6
                    options.inLayerSpacingFactor = 2
                    options.spacing = 50
                }
                if (mediumGraph) {
                    options.edgeSpacingFactor = 0.5
                    options.inLayerSpacingFactor = 1.6
                    options.spacing = 50
                }
                if (greatGraph) {
                    options.edgeSpacingFactor = 0.5
                    options.inLayerSpacingFactor = 1.5
                    options.spacing = 50
                }
            } else {
                options.direction = 'RIGHT'
                if (tinyGraph) {
                    options.nodePlacement = 'INTERACTIVE'
                    options.edgeSpacingFactor = 0.5
                    options.inLayerSpacingFactor = 3
                    options.spacing = 90
                }
                if (mediumGraph) {
                    options.edgeSpacingFactor = 0.5
                    options.inLayerSpacingFactor = 2.2
                    options.spacing = 60
                }
                if (greatGraph) {
                    options.edgeSpacingFactor = 0.5
                    options.inLayerSpacingFactor = 1.2
                    options.spacing = 50
                }
            }
            return options
        },

        // System of detect overlapping nodes
        detectOverlappingNodes () {
            const nodes = this.cy.nodes()
            const overlappingPairs = {
                nodes: [],
                topNode: null,
            }
            const test = [{
                id: null,
                position: null,
            }]

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < nodes.length; i++) {
                test[i] = {
                    id: nodes[i].data('id'),
                    position: nodes[i].position(),
                }
                // eslint-disable-next-line no-plusplus
                for (let j = i + 1; j < nodes.length; j++) {
                    const nodeA = nodes[i]
                    const nodeB = nodes[j]

                    const posA = nodeA.position()
                    const posB = nodeB.position()

                    const sizeA = nodeA.width()
                    const sizeB = nodeB.width()

                    if (Math.abs(posA.x - posB.x) < (sizeA + sizeB) / 2 &&
                        Math.abs(posA.y - posB.y) < (sizeA + sizeB) / 2) {
                        overlappingPairs.nodes.push([nodeA.data('id'), nodeB.data('id')])
                        overlappingPairs.topNode = this.cy.elements().indexOf(nodeA) > this.cy.elements().indexOf(nodeB) ? nodeA.data('id') : nodeB.data('id')
                    }
                }
            }
            if (overlappingPairs.nodes.length > 0) {
                overlappingPairs.nodes.forEach((pair) => {
                    const nodeA = this.cy.getElementById(pair[0])
                    const nodeB = this.cy.getElementById(pair[1])
                    console.log(`Overlapping nodes: ${nodeA.data('id')} and ${nodeB.data('id')}`)
                })
            }
        },

        adjustSelfLoops () {
            this.cy.edges().forEach((edge) => {
                const source = edge.source()
                const target = edge.target()
                if (source.data('id') === target.data('id')) {
                    const nodeWidth = parseFloat(source.style('width')) / 2.2
                    const newControlPointStepSize = nodeWidth < 130 ? 130 : nodeWidth
                    edge.style('control-point-step-size', newControlPointStepSize)
                }
            })
        },

        adjustZoomByNodes () {
            if (this.cy.nodes().length <= 5) {
                this.cy.zoom({ level: 1.1 })
                this.cy.center()
            } else {
                this.cy.center()
            }
        },

        enableLoopsAndOverlaps (data, model, onlyColor) {
            if (onlyColor) {
                this.enableLOActivities(data, model)
                this.enableLOArcs(data, model)
            } else {
                this.enableLOActivities(data)
                this.enableLOArcs(data, model)
            }
        },

        enableLOActivities ({
            activities,
            frequency,
            loops_activities: loopsActivities,
            activitiesExecuted: executed,
        },
        model) {
            const loopsActivitiesIds = (loopsActivities || []).map(act => act.name)
            const onlyExecutedActivities = Object.entries(executed || []).filter(([key, value]) => value === true).map(([key, value]) => Number(key))
            const executedNames = activities?.filter(act => onlyExecutedActivities.includes(act.id)).map(act => act.name)
            this.cy.nodes().forEach((node) => {
                if (node.data('id') === 'START') node.style('border-color', '#01C38E')
                else if (node.data('id') === 'END') node.style('border-color', '#FABFC5')
                else if (this.settings?.zoomerConfig?.groupedActivities?.some(group => group.name === node.data('id'))) {
                    const nodeActivity = activities.find(activity => activity.name === node.data('id'))
                    const activityHeatMap = {
                        duration: 0.098,
                        frequency: nodeActivity.frequency / frequency,
                    }
                    const modelType = (model || {}).values || Utils.graphValues.BOTH
                    if (node) {
                        const dotColor = Utils.getHeatMapColors(activityHeatMap)[modelType !== Utils.graphValues.BOTH ? modelType : Utils.graphValues.FREQ]
                        node.style('border-color', dotColor)
                    }
                    node.style('background-color', '#f0f7fa')
                    node.style('border-style', 'double')
                } else {
                    const nodeActivity = activities.find(activity => activity.name === node.data('id'))
                    const activityHeatMap = {
                        duration: 0.098,
                        frequency: nodeActivity.frequency / frequency,
                    }
                    const modelType = (model || {}).values || Utils.graphValues.BOTH
                    if (node) {
                        const dotColor = Utils.getHeatMapColors(activityHeatMap)[modelType !== Utils.graphValues.BOTH ? modelType : Utils.graphValues.FREQ]
                        node.style('border-color', dotColor)
                    }
                }
                if (node) {
                    if (loopsActivitiesIds.includes(node.data('id'))) {
                        node.style('border-color', '#f29304')
                    } else if (executedNames.includes(node.data('id'))) {
                        node.style('stroke', Svg.executed.rectColor)
                    }
                }
            })
        },

        enableLOArcs ({ loop_arcs: loopArcs, arcs: allArcs }, { statsValue: durMode, values: dataMode }) {
            const loopsArcsId = (loopArcs || []).map(arc => `arc-node-${nameFormatter(arc.name)}`)
            const overlapArcsId = dataMode !== Utils.graphValues.FREQ
                ? (allArcs || []).filter(arc => Utils.getStatValue(arc.duration, durMode) < 0).map(arc => `arc-node-${nameFormatter(arc.name)}`)
                : []
            this.cy.edges().forEach((edge) => {
                if (edge) {
                    if (loopsArcsId.includes(edge.data('id'))) {
                        edge.style('line-color', Svg.loops.pathColor)
                        edge.style('target-arrow-color', Svg.loops.arrowColor)
                    } else if (overlapArcsId.includes(edge.data('id'))) {
                        edge.style('line-color', Svg.overlap.pathColor)
                        edge.style('target-arrow-color', Svg.overlap.arrowColor)
                    }
                }
            })
        },

        colorLoops () {
            if (this.color !== undefined) {
                console.log('Holiii color')
                this.enableLoopsAndOverlaps(this.color, this.model, true)
            }
        },

        centerGraph () {
            this.resetInitialPosition()
            this.cy.fit(this.cy.elements(), 50)
            this.handleSessionStorage('remove')
        },

        saveInitialPosition () {
            this.cy.nodes().forEach((node) => {
                node.data('initialPosition', { x: node.position().x, y: node.position().y })
            })
        },

        resetInitialPosition () {
            this.cy.nodes().forEach((node) => {
                const originalPosition = node.data('initialPosition')
                node.position({
                    x: originalPosition.x,
                    y: originalPosition.y,
                })
            })
        },

        downloadGraph (format) {
            if (format.value === 'SVG') {
                this.downloadSVG()
            } else {
                this.downloadPNGorJPG(format)
            }
        },

        calculateScale (variants) {
            const maxScale = 2.0
            const minScale = 0.33
            const threshold = 10000

            if (variants > threshold) {
                return minScale
            }
            const ratio = variants / threshold

            return maxScale - (maxScale - minScale) * (ratio ** 0.2)
        },

        downloadPNGorJPG (format) {
            const link = document.createElement('a')
            const scale = this.calculateScale(this.data?.variants)
            const maxWidth = 35000
            const maxHeight = 35000
            link.download = `Graph.${format.value.toLowerCase()}`
            link.href = format.value === "PNG"
                ? this.cy.png({ full: true, scale, maxWidth, maxHeight })
                : this.cy.jpg({ full: true, scale, maxWidth, maxHeight })
            link.click()
        },

        downloadSVG () {
            const svgContent = this.cy.svg({ scale: 1 })
            const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' })
            const link = document.createElement('a')
            link.download = 'Graph.svg'
            link.href = URL.createObjectURL(blob)
            link.click()
        },

        listenNodeHover () {
            this.cy.on('mouseover', 'node', (event) => {
                const node = event.target
                const name = node.data('id')
                if (node.style('border-color') === 'rgb(242,147,4)') {
                    node.style('background-color', '#fab348')
                    return
                }
                const highlightColor = colorMap[name] || '#0f9ffc'
                node.style('background-color', highlightColor)
            })

            this.cy.on('mouseout', 'node', (event) => {
                const node = event.target
                this.settings?.zoomerConfig?.groupedActivities?.some(group => group.name === node.data('id'))
                    ? node.style('background-color', '#f0f7fa')
                    : node.style('background-color', '')
            })
        },

        saveNodesPositionByVariant () {
            const nodes = this.cy.nodes()
            nodes.forEach((node) => {
                const name = node.data('id')
                const position = node.position()
                this.nodesPosition[name] = position
            })
            this.handleSessionStorage('set')
        },

        handleSessionStorage (op) {
            const variant = this.data?.variantId || this.data?.variants
            const orientation = this.model?.directionValue
            const key = JSON.stringify({ variant, orientation })
            const info = JSON.stringify({ position: this.nodesPosition })
            switch (op) {
                case 'get':
                    return sessionStorage.getItem(key)
                case 'set':
                    return sessionStorage.setItem(key, info)
                case 'remove':
                    return sessionStorage.removeItem(key)
                default:
                    return sessionStorage.getItem(key)
            }
        },

        applyNodesPositionByVariant (positions) {
            const nodes = this.cy.nodes()
            nodes.forEach((node) => {
                const name = node.data('id')
                const position = JSON.parse(positions).position[name]
                if (position) {
                    node.position(position)
                }
            })
        },
        listenEdgeHover () {
            this.cy.on('mouseover', 'edge', (event) => {
                const edge = event.target
                edge.addClass('highlighted-edge')
            })
            this.cy.on('mouseout', 'edge', (event) => {
                const edge = event.target
                edge.removeClass('highlighted-edge')
            })
        },

        listenNodeMove () {
            this.cy.on('dragfree', 'node', (event) => {
                this.saveNodesPositionByVariant()
            })
        },

        setEndStartNodes (modelDirection) {
            const startNode = this.cy.getElementById('START')
            const endNode = this.cy.getElementById('END')
            if (!startNode || !endNode) return

            if (modelDirection === 'TB') {
                const nodesExceptEnd = this.cy.nodes().filter(n => n.id() !== 'END')
                const nodesExceptStart = this.cy.nodes().filter(n => n.id() !== 'START')

                if (nodesExceptEnd.length > 0 && nodesExceptStart.length > 0) {
                    const minY = Math.min(...nodesExceptStart.map(n => n.position('y')))
                    const maxY = Math.max(...nodesExceptEnd.map(n => n.position('y')))
                    if (startNode.position('y') >= minY) {
                        startNode.position({ x: startNode.position('x'), y: minY - 100 })
                    }
                    if (endNode.position('y') <= maxY) {
                        endNode.position({ x: startNode.position('x'), y: maxY + 100 })
                    }
                    startNode.position({ x: startNode.position('x'), y: startNode.position('y') })
                    endNode.position({ x: startNode.position('x'), y: endNode.position('y') })
                }
            } else {
                const nodesExceptEnd = this.cy.nodes().filter(n => n.id() !== 'END')
                const nodesExceptStart = this.cy.nodes().filter(n => n.id() !== 'START')

                if (nodesExceptEnd.length > 0 && nodesExceptStart.length > 0) {
                    const minX = Math.min(...nodesExceptStart.map(n => n.position('x')))
                    const maxX = Math.max(...nodesExceptEnd.map(n => n.position('x')))
                    if (startNode.position('x') >= minX) {
                        startNode.position({ x: minX - 150, y: startNode.position('y') })
                    }
                    if (endNode.position('x') <= maxX) {
                        endNode.position({ x: maxX + 100, y: startNode.position('y') })
                    }
                    startNode.position({ x: startNode.position('x'), y: startNode.position('y') })
                    endNode.position({ x: endNode.position('x'), y: startNode.position('y') })
                }
            }
        },

        // POPUPs METHODS

        openNodePopup () {
            const groupData = this.settings?.zoomerConfig?.groupedActivities
            this.cy.on('tap', 'node', (event) => {
                const node = event.target
                if (node.data('id') === 'START' ||
                    node.data('id') === 'END') return
                if (node.data('id') === this.selectedActivityPopup) {
                    this.closeActivityPopup()
                    return
                }
                this.closeArcPopup()
                const pos = this.calcPositionPopup(node)

                if (groupData && groupData.some(group => group.name === node.data('id'))) {
                    this.isGroupActivities = true
                    this.popupActivityData = groupData.find(group => group.name === node.data('id'))
                    this.selectedActivityPopup = this.popupActivityData.name
                    const nodeBox = node.renderedBoundingBox()
                    this.popupPosition = {
                        x: (nodeBox.x1 + (nodeBox.x2 - nodeBox.x1) / 2) + 100,
                        y: (nodeBox.y1 + (nodeBox.y2 - nodeBox.y1) / 2) - 40,
                    }
                    event.stopPropagation()
                    this.isActivityPopupVisible = true
                } else {
                    this.popupActivityData = node.data()
                    this.popupPosition = {
                        x: pos.x,
                        y: pos.y,
                    }
                    event.stopPropagation()
                    this.isGroupActivities = false
                    this.isActivityPopupVisible = true
                    this.selectedActivityPopup = node.data('id')
                }
            })
            // If user changes variant of the graph
            if (this.isActivityPopupVisible && this.selectedActivityPopup) {
                if (groupData && groupData.some(group => group.name === this.selectedActivityPopup)) {
                    const node = this.cy.getElementById(this.selectedActivityPopup)
                    if (node.data() === undefined) {
                        this.closeActivityPopup()
                    } else {
                        this.popupActivityData = groupData.find(group => group.name === node.data('id'))
                        const nodeBox = node.renderedBoundingBox()
                        this.popupPosition = {
                            x: (nodeBox.x1 + (nodeBox.x2 - nodeBox.x1) / 2) + 100,
                            y: (nodeBox.y1 + (nodeBox.y2 - nodeBox.y1) / 2) - 40,
                        }
                    }
                } else {
                    const node = this.cy.getElementById(this.selectedActivityPopup)
                    if (node.data() === undefined) {
                        this.closeActivityPopup()
                    } else {
                        this.popupActivityData = node.data()
                        const newPos = this.calcPositionPopup(node)
                        this.popupPosition = {
                            x: newPos.x,
                            y: newPos.y,
                        }
                    }
                }
            }
        },

        openArcPopup () {
            const groupData = this.settings?.zoomerConfig?.groupedActivities
            this.cy.on('tap', 'edge', (event) => {
                const edge = event.target
                if (edge.data('source') === 'START' || edge.data('target') === 'END' ||
                (groupData && groupData.some(group => group.name === edge.data('source'))) ||
                (groupData && groupData.some(group => group.name === edge.data('target')))) return
                if (edge.data('id') === this.selectedArcPopup) {
                    this.closeArcPopup()
                    return
                }
                this.closeActivityPopup()
                const pos = this.calcPositionPopup(edge)
                this.popupArcData = edge.data()
                this.popupPosition = {
                    x: pos.x,
                    y: pos.y,
                }
                this.isArcPopupVisible = true
                this.selectedArcPopup = edge.data('id')
            })
            // If user changes variant of the graph
            if (this.isArcPopupVisible && this.selectedArcPopup) {
                const edge = this.cy.getElementById(this.selectedArcPopup)
                if (edge.data() === undefined) {
                    this.closeArcPopup()
                } else {
                    this.popupArcData = edge.data()
                    const newPos = this.calcPositionPopup(edge)
                    this.popupPosition = {
                        x: newPos.x,
                        y: newPos.y,
                    }
                }
            }
        },

        calcPositionPopup (el) {
            const elementBox = el.renderedBoundingBox()
            const popupHeight = 390
            const popupWidth = 330
            const offsetX = 80
            const offsetY = 40
            const container = document.querySelector('.ChartContainer')
            const visibleAreaHeight = container.getBoundingClientRect().height
            let popupX = elementBox.x1 + (elementBox.x2 - elementBox.x1) / 2 + offsetX
            let popupY = elementBox.y1 + (elementBox.y2 - elementBox.y1) / 2 - offsetY

            if (popupX + popupWidth > container.getBoundingClientRect().width) {
                popupX = elementBox.x1 - popupWidth - offsetX
            } else if (popupX < 0) {
                popupX = elementBox.x2 + offsetX
            }

            popupX = Math.max(0, Math.min(popupX, container.getBoundingClientRect().width - offsetX))

            if (popupY + popupHeight > visibleAreaHeight) {
                popupY = elementBox.y1 - popupHeight - offsetY
            } else if (popupY < popupHeight / 2) {
                popupY = elementBox.y2 + offsetY
            }
            popupY = Math.max(0, Math.min(popupY, visibleAreaHeight - popupHeight))

            return {
                x: popupX,
                y: popupY,
            }
        },

        closeActivityPopup () {
            this.isActivityPopupVisible = false
            this.selectedActivityPopup = null
            this.popupPosition = null
        },
        closeArcPopup () {
            this.isArcPopupVisible = false
            this.selectedArcPopup = null
            this.popupPosition = null
        },
        handleAddFilterPopUp (filter) {
            this.$emit('add-filter', filter)
        },
        handleDeleteFilterPopUp (filter) {
            this.$emit('delete-filter', filter)
        },

        // FREQ INDICATOR METHODS

        calcMinPath () {
            if (this.cy.elements().nodes().length <= 2) {
                return
            }
            this.totalPath = this.cy.elements().nodes()
            const totalPathLength = this.totalPath.length - 2
            this.minPath = this.$refs.frequencyIndicator.calcMinPath(this.cy, 'START', 'END')
            const minPathNodesCount = this.minPath.filter(item => item.isNode()).length - 2

            this.minPercent = Math.round((minPathNodesCount / totalPathLength) * 100)
            this.calculateValidPercentages()
        },

        calculateValidPercentages () {
            const validPercentages = []
            const percentageToNodesMap = {}
            let currentPath = this.minPath
            let currentNodesCount = currentPath.filter(item => item.isNode()).length

            percentageToNodesMap[this.minPercent] = [...currentPath.filter(item => item.isNode()).map(node => node.data('id'))]
            validPercentages.push(this.minPercent)

            while (currentNodesCount < this.totalPath.length) {
                const nextActivity = this.calcSymmetricDifferencePath(currentPath).nodes().first()
                const prevActivitiesToDraw = []
                const postActivitiesToDraw = []

                // eslint-disable-next-line no-loop-func
                const checkPrevActivities = (node) => {
                    if (!node || node.data('id') === 'START' || node.data('id') === 'END') return
                    if (!currentPath.includes(node) && !prevActivitiesToDraw.includes(node)) {
                        prevActivitiesToDraw.push(node)
                        const prevNode = node?.incomers().nodes().first()
                        if (prevNode) checkPrevActivities(prevNode)
                    }
                }

                // eslint-disable-next-line no-loop-func
                const checkPostActivities = (node) => {
                    if (!node || node.data('id') === 'START' || node.data('id') === 'END') return
                    if (!currentPath.includes(node) && !postActivitiesToDraw.includes(node)) {
                        postActivitiesToDraw.push(node)
                        const nextNode = node?.outgoers().nodes().first()
                        if (nextNode) checkPostActivities(nextNode)
                    }
                }

                checkPrevActivities(nextActivity)
                checkPostActivities(nextActivity)
                const activitiesToDraw = [...new Set([...prevActivitiesToDraw, ...postActivitiesToDraw])]
                currentPath = currentPath.concat(activitiesToDraw)

                currentNodesCount = currentPath.filter(item => item.isNode()).length

                const newPercent = Math.round(((currentNodesCount) / (this.totalPath.length)) * 100)
                validPercentages.push(newPercent)
                percentageToNodesMap[newPercent] = [...currentPath.filter(item => item.isNode()).map(node => node.data('id'))]
            }

            this.validKnobValues = validPercentages.sort((a, b) => b - a)
            this.percentageMap = percentageToNodesMap
        },

        calcSymmetricDifferencePath (path) {
            return this.totalPath.filter(node => !path.includes(node)).sort((a, b) => a.data('freq') - b.data('freq'))
        },

        removeLessFreqActivities () {
            if (this.actualPercentIndex < this.validKnobValues.length - 1) {
                // eslint-disable-next-line no-plusplus
                this.actualPercentIndex++
                this.$refs.frequencyIndicator.updatePercent(this.validKnobValues[this.actualPercentIndex])
                this.drawPercentNodes(this.percentageMap[this.validKnobValues[this.actualPercentIndex]])
            }
        },

        addLessFreqActivities () {
            if (this.actualPercentIndex > 0) {
                // eslint-disable-next-line no-plusplus
                this.actualPercentIndex--
                this.$refs.frequencyIndicator.updatePercent(this.validKnobValues[this.actualPercentIndex])
                this.drawPercentNodes(this.percentageMap[this.validKnobValues[this.actualPercentIndex]])
            }
        },

        drawPercentNodes (activityNames) {
            this.cy.nodes().forEach((node) => {
                if (!activityNames.includes(node.id())) node.hide()
                else node.show()
            })
        },

        resetFreqIndictadorValues () {
            this.minPath = []
            this.totalPath = []
            this.validKnobValues = []
            this.percentageMap = {}
            this.actualPercentIndex = 0
            this.$refs.frequencyIndicator?.resetInitialValues()
        },

        // ZOOMERINDICATOR METHODS

        async checkZoomerActivities () {
            const groupedActivities = this.settings?.zoomerConfig?.groupedActivities || []
            const excludedActivities = this.settings?.zoomerConfig?.excludedActivities || []
            if (!groupedActivities.length && !excludedActivities.length) {
                this.$emit('zoomerIsVisible', false)
                return
            }
            const data = await this.checkData({
                variants: this.data?.variants,
                variantsUids: this.data?.variantId,
                useZoomer: false,
            })
            const activities = data?.data?.[0]?.activities || []
            if (!activities) return
            const groupedExists = this.cy.nodes().some(node => groupedActivities.some(group => node.data('id') === group.name))
            const excludedExists = activities.some(activity => excludedActivities.some(excluded => excluded === activity.name))
            if (groupedExists || excludedExists) this.$emit('zoomerIsVisible', true)
            else this.$emit('zoomerIsVisible', false)
        },

        formatData (data = {}) {
            const activitiesIds = (data.activities || []).reduce((acc, { id, name }, i) => ({ ...acc, [id]: name }), {})
            const arcs = (data.arcs || []).map(({ source, target, ...rest }) => ({
                ...rest,
                source: activitiesIds[source],
                target: activitiesIds[target],
                sourceId: source,
                targetId: target,
            }))
            return { ...data, arcs }
        },

        async checkData ({ variants, variantsUids, useZoomer = false }) {
            const { processId } = this.$route.params
            const { visualizationFilters } = this
            const { filters, filterSetsUUIDs, generalOperator } = this.splitFilterAndFilterSets(visualizationFilters)
            this.currentFilters = filters

            const groupedActivities = useZoomer ? this.settings?.zoomerConfig?.groupedActivities || [] : []
            const excludedActivities = useZoomer ? this.settings?.zoomerConfig?.excludedActivities || [] : []

            const params = {
                groupedActivities,
                excludedActivities,
                filters,
                filterSets: filterSetsUUIDs,
                operator: generalOperator,
                ...(variants ? { variants } : {}),
                ...(variantsUids ? { variantsUids } : {}),
            }

            if (variantsUids) {
                params.limit = 8
                params.start = 0
                return apiRequest(Api().visualizations.multipleVariants({ processId, params })).catch(notifyError)
            }
            return apiRequest(Api().visualizations.model({ processId, params })).catch(notifyError)
        },

        async toggleZoomerView (status = true) {
            try {
                const data = await this.checkData({
                    variants: this.data?.variants,
                    variantsUids: this.data?.variantId,
                    useZoomer: status,
                })
                this.fetchGraph(data.data?.[0])
            } catch (error) {
                console.error(error)
            }
        },

    },
}
</script>

<style lang="scss">
.Graph {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
}

.title-label {
  font-weight: bold;
  font-size: 14px;
  margin: 0;
}

.frequency-label {
  font-size: 12px;
  color: #666;
  margin: 0;
}
.popup-content {
  position: absolute;
  z-index: 1010;
}
.frequency-indicator {
    position: fixed;
    bottom: 90px;
}
.unrenderable {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    font-size: 18px;
    border: solid 1px;
}
</style>
