<template>
    <div class="Datasets">
        <Transition
            name="fade"
            mode="out-in"
        >
            <div v-if="!openSettingsModal">
                <div class="Actions flex row-left-center mb-1">
                    <QBtn
                        color="primary"
                        noCaps
                        :label="$t('projects.datasets.new')"
                        :disable="isProjectArchived"
                        icon="add_circle_outline"
                        @click="handleCreateItem"
                    />
                    <!-- <QBtn -->
                    <!--     class="ml-1" -->
                    <!--     color="primary" -->
                    <!--     outline -->
                    <!--     noCaps -->
                    <!--     :disable="isProjectArchived" -->
                    <!--     @click="handleClickImport" -->
                    <!-- > -->
                    <!--     <QIcon -->
                    <!--         name="cloud_upload" -->
                    <!--         class="material-symbols-outlined" -->
                    <!--         left -->
                    <!--     /> -->
                    <!--     {{ $t('projects.datasets.import') }} -->
                    <!-- </QBtn> -->
                </div>
                <div class="Container">
                    <DatasetsListCards
                        ref="table"
                        :rows="datasets"
                        :total="total"
                        :search="(filters || {}).search"
                        :archived="isProjectArchived"
                        :loading="isFetching"
                        @on-new-item="handleCreateItem"
                        @on-delete="handleDeleteItem"
                        @on-upload="handleUploadItem"
                        @on-fetch-data="fetchData"
                        @on-visualization="handleGoToVisualization"
                        @on-settings="handleOpenSettings"
                        @on-move="handleOpenMoveDatasetModal"
                    />
                </div>
            </div>
            <SettingsModal
                v-else
                ref="settingsModal"
                :datasetInfo="datasetSettings"
                :isOpen="openSettingsModal"
                @update-dataset="handleUpdateItem"
                @set-updates="handleSetItemUpdates"
                @close-settings="handleCloseSettings"
            />
        </Transition>
        <MoveDatasetModal
            v-if="openMoveModal"
            :dataset="selectedDataset"
            :currentProjectId="projectId"
            :isOpen="openMoveModal"
            @close="openMoveModal = false"
            @moved="handleDatasetMoved"
        />
        <ModalRequest
            ref="deleteDialog"
            :title="$t('projects.datasets.delete')"
            :text="$t('projects.datasets.modals.delete.text', { name: (selected || {}).name })"
            :loading="isLoading"
            @on-accept="archiveDataset"
        />

        <ModalNameDesc
            ref="newDialog"
            :title="$t('projects.datasets.new')"
            :action="$t('actions.create')"
            :loading="isLoading"
            @on-submit="createDataset"
        />

        <ModalNameDesc
            ref="updateDialog"
            :title="$t('projects.datasets.edit')"
            :action="$t('actions.save')"
            :loading="isLoading"
            withoutDesc
            @on-submit="updateDataset"
        />

        <UploadDialog
            ref="uploadDialog"
            @finish-update="fetchData"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { pollingMixin, filtersStorageMixin } from '@/mixins'
import { ModalRequest, ModalNameDesc } from '@/components'
import { WIZARD } from '@/entities'
import {
    DatasetsListCards, UploadDialog, SettingsModal, MoveDatasetModal,
} from './sections'

const DEFAULT_VISUALIZATION = 'MODEL'

export default {
    name: 'Datasets',
    components: {
        ModalRequest,
        DatasetsListCards,
        ModalNameDesc,
        UploadDialog,
        SettingsModal,
        MoveDatasetModal,
    },
    mixins: [pollingMixin, filtersStorageMixin],
    props: {
        projectId: VueTypes.string,
        isProjectArchived: VueTypes.bool,
        filters: VueTypes.shape({
            search: VueTypes.string,
            archived: VueTypes.oneOfType([VueTypes.string, VueTypes.bool]),
        }),
    },
    data () {
        return {
            firstTime: true,
            datasets: undefined,
            total: 0,
            isFetching: false,
            datasetSettings: undefined,
            openSettingsModal: false,
            selected: undefined,
            isLoading: false,
            openMoveModal: false,
        }
    },
    computed: {
        params () {
            const { table } = this.$refs
            const pagination = table ? table.getPagination() : {}
            return {
                ...(this.filters || {}),
                ...pagination,
            }
        },
    },
    watch: {
        filters: {
            handler (value) {
                if (value) this.fetchData()
            },
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        handleCreateItem () {
            this.$storage.remove(WIZARD.KEYS.DATASET)
            this.$router.push({ name: 'WizardDataset', params: { projectId: this.projectId } })
        },
        handleClickImport () {
            this.$storage.remove(WIZARD.KEYS.IMPORT)
            this.$router.push({ name: 'WizardImport', params: { projectId: this.projectId } })
        },
        handleDeleteItem (row) {
            this.selected = row
            this.$refs.deleteDialog.open()
        },
        handleUpdateItem (datasetInfo) {
            this.updateDataset(datasetInfo)
        },
        handleSetItemUpdates (updateInfo) {
            this.setDatasetUpdates(updateInfo)
        },
        handleUploadItem ({ uuid }) {
            this.$refs.uploadDialog.open(uuid)
        },
        handleOpenSettings (row) {
            this.datasetSettings = row
            this.openSettingsModal = true
        },
        handleCloseSettings () {
            this.openSettingsModal = false
            this.datasetSettings = undefined
        },
        handleOpenMoveDatasetModal (dataset) {
            this.selectedDataset = dataset
            this.openMoveModal = true
        },
        handleCloseMoveDataset () {
            this.openMoveModal = false
            this.selectedDataset = null
        },
        handleDatasetMoved () {
            this.openMoveModal = false
            this.fetchData()
        },
        handleGoToDataset ({ uuid: datasetId }) {
            this.$router.push({ name: 'Dataset', params: { projectId: this.projectId, datasetId } })
        },
        handleGoToVisualization ({ uuid: processId, name, opened }) {
            const { projectId } = this
            this.setVisualizationFilters([])
            if (!opened) {
                this.checkInitialView(projectId, processId, name)
            } else {
                sessionStorage.removeItem('sessionCharts')
                this.$router.push({ name: 'ProcessVisualization', params: { projectId, processId, tab: DEFAULT_VISUALIZATION } })
            }
        },
        async fetchData ({ polling = false } = {}) {
            const {
                archived, search, page, rowsPerPage,
            } = this.params
            const params = {
                projectUid: this.projectId,
                includeArchived: !!archived,
                ...(rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                search: search || null,
                polling,
            }

            if (!(this.datasets || []).length) this.isFetching = true
            apiRequest(Api().datasets.list(params))
                .then(({ data, total }) => {
                    this.datasets = data
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        async createDataset ({ name, description }) {
            this.isLoading = true
            apiRequest(Api().datasets.create({ projectUid: this.projectId, name, description }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.created.text'))
                    this.fetchData()
                    this.$refs.newDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async updateDataset (datasetInfo) {
            const { uuid: datasetId } = this.datasetSettings
            this.isLoading = true
            apiRequest(Api().datasets.update({ datasetId, params: { name: datasetInfo.name, description: datasetInfo.description } }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.updated.text'))
                    this.fetchData()
                    this.datasetSettings.name = datasetInfo.name
                    this.datasetSettings.description = datasetInfo.description
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        async setDatasetUpdates (newSettings) {
            const { uuid: datasetId } = this.datasetSettings
            this.isLoading = true
            apiRequest(Api().datasets.update({ datasetId, params: newSettings }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.updated.text'))
                    this.fetchData()
                    this.datasetSettings = {
                        ...this.datasetSettings,
                        ...newSettings,
                    }
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        async archiveDataset () {
            const { uuid: datasetId } = this.selected
            this.isLoading = true
            apiRequest(Api().datasets.delete({ datasetId }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.deleted.text'))
                    this.fetchData()
                    this.$refs.deleteDialog.close()
                    this.selected = undefined
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async checkInitialView (projectId, processId, name) {
            // eslint-disable-next-line prefer-destructuring
            apiRequest(Api().datasets.getConfigurations({ datasetId: processId }))
                .then((data) => {
                    const relevantColumns = data.data[0].columns.filter(column => column.relevant).map(column => column.name)
                    const categoricalColumns = data.data[0].columns.filter(column => column.type === 'STRING').map(column => column.name)
                    if (relevantColumns.length === 0 && categoricalColumns.length === 0) {
                        apiRequest(Api().datasets.setOpened({ datasetId: processId }))
                            .then(() => {
                                this.$router.push({
                                    name: 'ProcessVisualization',
                                    params: {
                                        projectId,
                                        processId,
                                    },
                                    query: {
                                        projectId,
                                        processId,
                                        datasetName: name,
                                        tab: DEFAULT_VISUALIZATION,
                                    },
                                })
                            })
                            .catch(notifyError)
                    } else {
                        this.$router.push({
                            name: 'WizardChooseApproach',
                            params: {
                                projectId,
                                processId,
                            },
                            query: {
                                projectId,
                                processId,
                                datasetName: name,
                                tab: DEFAULT_VISUALIZATION,
                            },
                        })
                    }
                })
                .catch(notifyError)
                .finally()
        },
    },
}
</script>
